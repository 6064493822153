import React, { useState, useRef } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  IconButton,
  CircularProgress,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Tooltip,
  ImageList,
  ImageListItem
} from '@mui/material';
import {
  Upload as UploadIcon,
  Refresh as RefreshIcon,
  FileCopy as CopyIcon,
  Collections as CollectionsIcon,
  Search as SearchIcon,
  Clear as ClearIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';

import { uploadImage, fetchExistingImages, deleteImage } from './imageService';
import { formatFileSize, formatUrlForDisplay } from './imageUtils';

/**
 * Individual image uploader component
 */
const ImageItem = ({ image, index, onUpload, onReset }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(image.error);
  const [showPreview, setShowPreview] = useState(false);
  const [showExistingImages, setShowExistingImages] = useState(false);
  const [existingImages, setExistingImages] = useState([]);
  const [filteredImages, setFilteredImages] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const fileInputRef = useRef(null);
  
  // Load existing images when dialog opens
  const loadExistingImages = async () => {
    try {
      setIsLoadingImages(true);
      const images = await fetchExistingImages();
      
      // Sort images by updateTime (most recent first)
      const sortedImages = [...images].sort((a, b) => {
        // Use updateTime if available, fall back to createTime
        const timeA = a.updateTime || a.createTime || 0;
        const timeB = b.updateTime || b.createTime || 0;
        return timeB - timeA;  // Descending order (newest first)
      });
      
      setExistingImages(sortedImages);
      setFilteredImages(sortedImages); // Initialize filtered images with all images
      setSearchTerm(''); // Reset search when loading new images
    } catch (err) {
      console.error('Failed to load existing images:', err);
      setError('Failed to load existing images. Please try again.');
    } finally {
      setIsLoadingImages(false);
    }
  };
  
  const handleSelectExistingImage = (selectedImage) => {
    // Update UI immediately
    const updatedImage = {
      ...image,
      newSrc: selectedImage.url, // Store the URL for HTML
      previewSrc: `${selectedImage.url}?t=${new Date().getTime()}`, // Cache-busted URL for preview
      uploaded: true,
      uploading: false,
      error: null
    };
    
    // Update parent component
    onUpload(index, updatedImage);
    setShowExistingImages(false);
  };
  
  const handleShowExistingImages = () => {
    setShowExistingImages(true);
    loadExistingImages();
  };
  
  // Handle search input change
  const handleSearchChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    
    if (!term.trim()) {
      // If search is empty, show all images
      setFilteredImages(existingImages);
    } else {
      // Filter images by filename
      const filtered = existingImages.filter(img => 
        img.filename && img.filename.toLowerCase().includes(term)
      );
      setFilteredImages(filtered);
    }
  };
  
  // Clear search
  const handleClearSearch = () => {
    setSearchTerm('');
    setFilteredImages(existingImages);
  };
  
  const handleFileSelect = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      
      // Update local state
      setIsUploading(true);
      setError(null);
      
      // Start upload
      uploadImage(file)
        .then(result => {
          // Create object URL for immediate thumbnail preview
          const imageUrl = result.url;
          
          // Force image cache refresh by adding a timestamp query parameter
          const cachedUrl = `${imageUrl}?t=${new Date().getTime()}`;
          
          // Update UI immediately
          const updatedImage = {
            ...image,
            file,
            newSrc: imageUrl, // Store the clean URL for HTML
            previewSrc: cachedUrl, // Use cache-busted URL for preview
            uploaded: true,
            uploading: false,
            error: null
          };
          
          // Update parent component
          onUpload(index, updatedImage);
          setIsUploading(false);
        })
        .catch(err => {
          // Format error message
          let errorMsg = "Upload failed";
          
          console.error("Image upload error details:", {
            message: err.message,
            stack: err.stack,
            name: err.name
          });
          
          if (err.message.includes("Network") || err.message.includes("Failed to fetch")) {
            errorMsg = "Network error. Please check your connection or try again.";
          } else if (err.message.includes("5MB")) {
            errorMsg = "File size exceeds 5MB limit.";
          } else if (err.message.includes("401")) {
            errorMsg = "Authentication error. Please log in again.";
          } else if (err.message.includes("403")) {
            errorMsg = "You don't have permission to upload images.";
          } else if (err.message.includes("415")) {
            errorMsg = "Invalid file type. Please use jpg, png, or gif.";
          } else if (err.message.includes("500")) {
            errorMsg = "Server error. Please try again later.";
          } else if (err.message.includes("timed out")) {
            errorMsg = "Upload timed out. Try with a smaller image or check your connection.";
          } else if (err.message.includes("aborted")) {
            errorMsg = "Upload was aborted. Please try again.";
          } else if (err.message) {
            errorMsg = err.message;
          }
          
          // Handle upload error
          setError(errorMsg);
          onUpload(index, {
            ...image,
            file,
            uploading: false,
            error: errorMsg
          });
          setIsUploading(false);
        });
    }
  };
  
  const handleReset = () => {
    setError(null);
    onReset(index);
  };
  
  // Handle opening delete confirmation dialog
  const handleOpenDeleteDialog = (img) => {
    setImageToDelete(img);
    setShowDeleteConfirm(true);
  };
  
  // Handle closing delete confirmation dialog
  const handleCloseDeleteDialog = () => {
    setShowDeleteConfirm(false);
    setImageToDelete(null);
  };
  
  // Delete image handler
  const handleDeleteImage = async () => {
    if (!imageToDelete || !imageToDelete.filename) {
      setError('Unable to delete image: Missing filename');
      setShowDeleteConfirm(false);
      return;
    }
    
    try {
      setIsDeleting(true);
      
      // Call the API to delete the image
      await deleteImage(imageToDelete.filename);
      
      // Remove the deleted image from the lists
      const updatedImages = existingImages.filter(img => img.filename !== imageToDelete.filename);
      setExistingImages(updatedImages);
      setFilteredImages(filteredImages.filter(img => img.filename !== imageToDelete.filename));
      
      // If the current image was deleted, reset it
      if (image.newSrc === imageToDelete.url) {
        onReset(index);
      }
      
      // Close dialog and clear delete state
      setShowDeleteConfirm(false);
      setImageToDelete(null);
    } catch (err) {
      console.error('Failed to delete image:', err);
      
      // Format error message
      let errorMsg = 'Failed to delete image';
      
      if (err.message.includes('Network') || err.message.includes('Failed to fetch')) {
        errorMsg = 'Network error. Please check your connection or try again.';
      } else if (err.message.includes('401')) {
        errorMsg = 'Authentication error. Please log in again.';
      } else if (err.message.includes('403')) {
        errorMsg = 'You don\'t have permission to delete images.';
      } else if (err.message.includes('404')) {
        errorMsg = 'Image not found. It may have been already deleted.';
      } else if (err.message.includes('500')) {
        errorMsg = 'Server error. Please try again later.';
      } else if (err.message) {
        errorMsg = err.message;
      }
      
      setError(errorMsg);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Paper 
      elevation={0} 
      sx={{
        p: 2,
        mb: 2,
        border: '1px solid',
        borderColor: error ? 'error.light' : 
                    image.uploaded ? 'success.light' : 
                    'divider',
        borderRadius: 2,
        backgroundColor: error ? 'rgba(211, 47, 47, 0.04)' : 
                         image.uploaded ? 'rgba(46, 125, 50, 0.04)' : 
                         'background.paper'
      }}
    >
      <Grid container spacing={2} alignItems="center">
        {/* Preview thumbnail */}
        <Grid item xs={12} sm={3}>
          <Box 
            sx={{ 
              width: '100%',
              height: 120,
              backgroundImage: `url(${image.previewSrc || image.newSrc || image.originalSrc})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundColor: 'rgba(0,0,0,0.04)',
              borderRadius: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.08)'
              }
            }}
            onClick={() => setShowPreview(true)}
          >
            {!image.originalSrc && !image.newSrc && (
              <Typography variant="body2" color="text.secondary">
                No preview
              </Typography>
            )}
          </Box>
        </Grid>
        
        {/* Image details */}
        <Grid item xs={12} sm={9}>
          <Box sx={{ mb: 1 }}>
            <Typography variant="subtitle2" gutterBottom>
              Image {index + 1} {image.alt && `(${image.alt})`}
            </Typography>
            
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                label="Current Source"
                value={formatUrlForDisplay(image.originalSrc) || ''}
                InputProps={{
                  readOnly: true,
                  sx: { fontFamily: 'monospace', fontSize: '0.8rem' }
                }}
              />
            </Box>
            
            {image.newSrc && (
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="New Source"
                  value={formatUrlForDisplay(image.newSrc)}
                  InputProps={{
                    readOnly: true,
                    sx: { fontFamily: 'monospace', fontSize: '0.8rem' },
                    endAdornment: (
                      <Tooltip title="Copy URL">
                        <IconButton 
                          edge="end" 
                          size="small"
                          onClick={() => {
                            navigator.clipboard.writeText(image.newSrc);
                          }}
                        >
                          <CopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )
                  }}
                />
              </Box>
            )}
            
            {image.file && (
              <Typography variant="caption" display="block" gutterBottom>
                {image.file.name} ({formatFileSize(image.file.size)})
              </Typography>
            )}
            
            {error && (
              <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
                {error}
              </Alert>
            )}
            
            {/* Upload actions */}
            <Box sx={{ mt: 2, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              <input
                ref={fileInputRef}
                accept="image/*"
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileSelect}
              />
              
              <Button
                variant="outlined"
                size="small"
                startIcon={isUploading ? <CircularProgress size={16} /> : <UploadIcon />}
                onClick={() => fileInputRef.current.click()}
                disabled={isUploading}
                sx={{ flexGrow: 1 }}
              >
                {image.uploaded ? 'Replace Image' : 'Upload Image'}
              </Button>
              
              <Button
                variant="outlined"
                size="small"
                startIcon={<CollectionsIcon />}
                onClick={handleShowExistingImages}
                disabled={isUploading}
                sx={{ flexGrow: 1 }}
              >
                Choose Existing
              </Button>
              
              {image.uploaded && (
                <Tooltip title="Reset to original">
                  <IconButton 
                    size="small"
                    onClick={handleReset}
                    color="default"
                  >
                    <RefreshIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      
      {/* Image preview dialog */}
      <Dialog
        open={showPreview}
        onClose={() => setShowPreview(false)}
        maxWidth="md"
      >
        <DialogTitle>
          Image Preview
        </DialogTitle>
        <DialogContent>
          <Box
            component="img"
            src={image.previewSrc || image.newSrc || image.originalSrc}
            alt={image.alt}
            sx={{
              maxWidth: '100%',
              maxHeight: '80vh',
              objectFit: 'contain',
              display: 'block',
              margin: '0 auto'
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPreview(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Existing images dialog */}
      <Dialog
        open={showExistingImages}
        onClose={() => setShowExistingImages(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Choose Existing Image
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2, position: 'relative' }}>
            <TextField
              fullWidth
              placeholder="Search images by filename..."
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{ mb: 2 }}
              variant="outlined"
              InputProps={{
                startAdornment: <SearchIcon color="action" sx={{ mr: 1 }} />,
                endAdornment: searchTerm ? (
                  <IconButton 
                    size="small" 
                    onClick={handleClearSearch}
                    edge="end"
                    aria-label="Clear search"
                  >
                    <ClearIcon />
                  </IconButton>
                ) : null
              }}
            />
          </Box>
          
          {isLoadingImages ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress />
            </Box>
          ) : existingImages.length > 0 ? (
            <>
              {filteredImages.length === 0 ? (
                <Alert severity="info" sx={{ mb: 2 }}>
                  No images match your search. Try different keywords.
                </Alert>
              ) : (
                <Box sx={{ mb: 1 }}>
                  <Typography variant="caption" color="text.secondary">
                    Showing {filteredImages.length} of {existingImages.length} images
                  </Typography>
                </Box>
              )}
              
              {filteredImages.length > 0 && (
              <ImageList cols={3} gap={8}>
                {filteredImages.map((img, idx) => (
                <ImageListItem 
                  key={`${img.url}-${idx}`}
                  onClick={() => handleSelectExistingImage(img)}
                  sx={{ 
                    cursor: 'pointer',
                    border: '1px solid #eee',
                    borderRadius: 1,
                    overflow: 'hidden',
                    transition: 'all 0.2s',
                    '&:hover': {
                      transform: 'scale(1.02)',
                      boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                    }
                  }}
                >
                  <img
                    src={img.url}
                    alt={img.filename || `Image ${idx + 1}`}
                    loading="lazy"
                    style={{ 
                      height: 140,
                      objectFit: 'contain',
                      padding: 8,
                      backgroundColor: '#f5f5f5'
                    }}
                  />
                  <Box 
                    sx={{ 
                      p: 1, 
                      display: 'flex', 
                      flexDirection: 'column'
                    }}
                  >
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}>
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          flexGrow: 1,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: 'block',
                          fontWeight: 'bold'
                        }}
                      >
                        {img.filename || `Image ${idx + 1}`}
                      </Typography>
                      <Tooltip title="Delete image">
                        <IconButton 
                          size="small" 
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent selecting the image
                            handleOpenDeleteDialog(img);
                          }}
                          sx={{ 
                            ml: 0.5,
                            '&:hover': { 
                              color: 'error.main' 
                            }
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Typography 
                      variant="caption" 
                      color="text.secondary"
                      sx={{ 
                        textAlign: 'center',
                        fontSize: '0.7rem'
                      }}
                    >
                      {img.contentType && img.contentType.split('/')[1].toUpperCase()}
                      {img.size && ` • ${formatFileSize(img.size)}`}
                    </Typography>
                  </Box>
                </ImageListItem>
              ))}
              </ImageList>
            )}
            </>
          ) : (
            <Alert severity="info" sx={{ m: 2 }}>
              No images available. Upload some images first.
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowExistingImages(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Delete confirmation dialog */}
      <Dialog
        open={showDeleteConfirm}
        onClose={handleCloseDeleteDialog}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          {imageToDelete && (
            <>
              <Box sx={{ mb: 2 }}>
                <Typography variant="body1">
                  Are you sure you want to permanently delete this image?
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  {imageToDelete.filename}
                </Typography>
              </Box>
              <Box 
                sx={{ 
                  display: 'flex',
                  justifyContent: 'center',
                  mb: 2 
                }}
              >
                <Box
                  component="img"
                  src={imageToDelete.url}
                  alt={imageToDelete.filename}
                  sx={{
                    maxWidth: '100%',
                    maxHeight: '150px',
                    objectFit: 'contain',
                    border: '1px solid #eee',
                    borderRadius: 1,
                    p: 1
                  }}
                />
              </Box>
              <Alert severity="warning">
                This action cannot be undone. If this image is used in any email templates, they will be broken.
              </Alert>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCloseDeleteDialog}
            disabled={isDeleting}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleDeleteImage}
            color="error"
            variant="contained"
            disabled={isDeleting}
            startIcon={isDeleting ? <CircularProgress size={16} /> : <DeleteIcon />}
          >
            {isDeleting ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default ImageItem;