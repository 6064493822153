import React, { useEffect } from 'react';
import { Box, TextField, Typography } from '@mui/material';

/**
 * Component for editing plain text version of the email template
 */
export const TextEditor = ({ value, onChange }) => {
  // Log when value prop updates
  useEffect(() => {
    console.log("TextEditor: value prop updated", {
      valueLength: value?.length,
      valueFirstChars: value?.substring(0, 20)
    });
  }, [value]);

  const handleChange = (e) => {
    console.log("TextEditor: handleChange called", {
      newValueLength: e.target.value?.length
    });
    onChange(e);
  };

  return (
    <Box>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        This plain text version will be used as a fallback for email clients that don't support HTML.
      </Typography>
      <TextField
        fullWidth
        multiline
        minRows={12}
        value={value}
        onChange={handleChange}
        placeholder="Enter plain text version of your email..."
        variant="outlined"
        InputProps={{
          sx: { fontFamily: 'monospace' }
        }}
      />
    </Box>
  );
};