import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box, 
  Button,
  Typography, 
  Paper,
  Tab,
  Tabs,
  CircularProgress,
  Alert,
  Snackbar
} from '@mui/material';
import { 
  Add as AddIcon
} from '@mui/icons-material';
import { colors } from '../admin/utils/constants';
import apiService from '../services/api';
import { formatDateWithTime } from '../utils/dateUtils';

// Import components
import CampaignCard from '../components/email/campaigns/CampaignCard';
import CampaignForm from '../components/email/campaigns/CampaignForm';
import ScheduleDialog from '../components/email/campaigns/ScheduleDialog';
import TestEmailDialog from '../components/email/campaigns/TestEmailDialog';
import CancelConfirmDialog from '../components/email/campaigns/CancelConfirmDialog';
import AnalyticsDialog from '../components/email/campaigns/AnalyticsDialog';

const EmailCampaignsPage = () => {
  // State for campaigns
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // State for filtering
  const [tabValue, setTabValue] = useState(0);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  
  // State for dialogs
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
  const [testEmailDialogOpen, setTestEmailDialogOpen] = useState(false);
  const [analyticsDialogOpen, setAnalyticsDialogOpen] = useState(false);
  const [cancelConfirmDialogOpen, setCancelConfirmDialogOpen] = useState(false);
  
  // State for form data
  const [currentCampaign, setCurrentCampaign] = useState(null);
  const [campaignForm, setCampaignForm] = useState({
    name: '',
    templateId: '',
    segmentId: '',
    trackOpens: true,
    trackClicks: true
  });
  
  // State for scheduling
  const [scheduledTime, setScheduledTime] = useState(new Date(Date.now() + 3600000)); // 1 hour from now
  
  // State for test email
  const [testEmail, setTestEmail] = useState('');
  
  // State for analytics
  const [campaignAnalytics, setCampaignAnalytics] = useState(null);
  const [analyticsLoading, setAnalyticsLoading] = useState(false);
  
  // State for templates and segments
  const [templates, setTemplates] = useState([]);
  const [segments, setSegments] = useState([]);
  
  // Notification state
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // Fetch campaigns from API
  const fetchCampaigns = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const response = await apiService.admin.getEmailCampaigns();
      let campaignsWithMetrics = response.campaigns || [];
      
      // Fetch analytics for completed campaigns
      const completedCampaigns = campaignsWithMetrics.filter(campaign => 
        ['COMPLETED', 'SENT'].includes(campaign.status)
      );
      
      // If there are completed campaigns, fetch their metrics
      if (completedCampaigns.length > 0) {
        // We'll use Promise.all to fetch metrics in parallel
        const metricsPromises = completedCampaigns.map(async (campaign) => {
          try {
            const analytics = await apiService.admin.getEmailCampaignAnalytics(campaign.id);
            return {
              campaignId: campaign.id,
              metrics: {
                openRate: analytics.openRate,
                clickRate: analytics.clickRate,
                bounceRate: analytics.bounceRate,
                totalRecipients: analytics.totalRecipients,
                totalOpens: analytics.totalOpens,
                totalClicks: analytics.totalClicks,
                totalBounces: analytics.totalBounces
              }
            };
          } catch (error) {
            console.error(`Error fetching metrics for campaign ${campaign.id}:`, error);
            return {
              campaignId: campaign.id,
              metrics: null
            };
          }
        });
        
        // Wait for all metrics to be fetched
        const metricsResults = await Promise.all(metricsPromises);
        
        // Merge metrics with campaigns
        campaignsWithMetrics = campaignsWithMetrics.map(campaign => {
          const metricsResult = metricsResults.find(m => m.campaignId === campaign.id);
          if (metricsResult && metricsResult.metrics) {
            return {
              ...campaign,
              metrics: metricsResult.metrics
            };
          }
          return campaign;
        });
      }
      
      setCampaigns(campaignsWithMetrics);
    } catch (err) {
      console.error('Error fetching campaigns:', err);
      setError('Failed to load campaigns. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Fetch email templates
  const fetchTemplates = async () => {
    try {
      const response = await apiService.admin.getEmailTemplates();
      setTemplates(response.templates || []);
    } catch (err) {
      console.error('Error fetching templates:', err);
    }
  };

  // Fetch user segments
  const fetchSegments = async () => {
    try {
      const response = await apiService.admin.getSegments();
      setSegments(response.segments || []);
    } catch (err) {
      console.error('Error fetching segments:', err);
    }
  };

  // Filter campaigns based on current tab
  const filterCampaigns = useCallback(() => {
    let filtered;
    
    switch (tabValue) {
      case 0: // All campaigns
        filtered = campaigns;
        break;
      case 1: // Drafts
        filtered = campaigns.filter(campaign => campaign.status === 'DRAFT');
        break;
      case 2: // Scheduled
        filtered = campaigns.filter(campaign => campaign.status === 'SCHEDULED');
        break;
      case 3: // Completed
        filtered = campaigns.filter(campaign => ['COMPLETED', 'SENT'].includes(campaign.status));
        break;
      default:
        filtered = campaigns;
    }
    
    setFilteredCampaigns(filtered);
  }, [tabValue, campaigns]);

  // Load campaigns, templates, and segments on component mount
  useEffect(() => {
    fetchCampaigns();
    fetchTemplates();
    fetchSegments();
  }, []);

  // Filter campaigns whenever tab changes or campaigns list updates
  useEffect(() => {
    filterCampaigns();
  }, [filterCampaigns]);

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Handle campaign form input changes
  const handleFormChange = (e) => {
    const { name, value, checked } = e.target;
    
    // For checkboxes, use the checked property
    if (name === 'trackOpens' || name === 'trackClicks') {
      setCampaignForm(prev => ({
        ...prev,
        [name]: checked
      }));
    } else {
      setCampaignForm(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  // Create a new campaign
  const handleCreateCampaign = async () => {
    try {
      const response = await apiService.admin.createEmailCampaign(campaignForm);
      
      setCampaigns(prev => [...prev, response]);
      setCreateDialogOpen(false);
      resetCampaignForm();
      
      showNotification('Campaign created successfully', 'success');
    } catch (err) {
      console.error('Error creating campaign:', err);
      showNotification('Failed to create campaign', 'error');
    }
  };

  // Schedule a campaign for sending
  const handleScheduleCampaign = async () => {
    if (!currentCampaign) return;
    
    try {
      // Validate campaign is in DRAFT status
      if (currentCampaign.status !== 'DRAFT') {
        showNotification('Only campaigns in DRAFT status can be scheduled', 'error');
        return;
      }
      
      // Ensure selected time is in the future with minimum 5 minute buffer
      const now = new Date();
      const minimumScheduleTime = new Date(now.getTime() + 5 * 60 * 1000); // 5 minutes from now
      
      if (scheduledTime < minimumScheduleTime) {
        showNotification('Scheduled time must be at least 5 minutes in the future', 'error');
        return;
      }
      
      // Prepare schedule data with proper timestamp
      const scheduleData = {
        scheduledTime: scheduledTime.getTime()
      };
      
      console.log(`Scheduling campaign ${currentCampaign.id} for ${scheduledTime.toLocaleString()}`);
      
      // Call the API to schedule the campaign
      const response = await apiService.admin.scheduleEmailCampaign(currentCampaign.id, scheduleData);
      
      // Update the campaign in the local state
      setCampaigns(prev => 
        prev.map(campaign => 
          campaign.id === response.id ? response : campaign
        )
      );
      
      // Close the dialog and show success notification
      setScheduleDialogOpen(false);
      showNotification('Campaign scheduled successfully', 'success');
      
      // Refresh the campaigns list to ensure we have the latest data
      fetchCampaigns();
    } catch (err) {
      console.error('Error scheduling campaign:', err);
      // Display a more specific error message if available
      const errorMessage = err.message || 'Failed to schedule campaign';
      showNotification(`Failed to schedule campaign: ${errorMessage}`, 'error');
    }
  };

  // Cancel a scheduled campaign
  const handleCancelCampaign = async () => {
    if (!currentCampaign) return;
    
    try {
      const response = await apiService.admin.cancelEmailCampaign(currentCampaign.id);
      
      // Update the campaign in the local state
      setCampaigns(prev => 
        prev.map(campaign => 
          campaign.id === response.id ? response : campaign
        )
      );
      
      setCancelConfirmDialogOpen(false);
      showNotification('Campaign cancelled successfully', 'success');
    } catch (err) {
      console.error('Error cancelling campaign:', err);
      showNotification('Failed to cancel campaign', 'error');
    }
  };

  // Send a test email
  const handleSendTestEmail = async () => {
    if (!currentCampaign || !testEmail) return;
    
    try {
      const testData = {
        receiverEmail: testEmail
      };
      
      await apiService.admin.testEmailCampaign(currentCampaign.id, testData);
      
      setTestEmailDialogOpen(false);
      setTestEmail('');
      showNotification(`Test email sent to ${testEmail}`, 'success');
    } catch (err) {
      console.error('Error sending test email:', err);
      showNotification('Failed to send test email', 'error');
    }
  };

  // View campaign analytics
  const handleViewAnalytics = async (campaign) => {
    setCurrentCampaign(campaign);
    setCampaignAnalytics(null);
    setAnalyticsLoading(true);
    setAnalyticsDialogOpen(true);
    
    try {
      const analytics = await apiService.admin.getEmailCampaignAnalytics(campaign.id);
      setCampaignAnalytics(analytics);
      
      // Update campaign with metrics in the campaigns list
      setCampaigns(prevCampaigns => 
        prevCampaigns.map(c => {
          if (c.id === campaign.id) {
            return {
              ...c,
              metrics: {
                openRate: analytics.openRate,
                clickRate: analytics.clickRate, 
                bounceRate: analytics.bounceRate,
                totalRecipients: analytics.totalRecipients,
                totalOpens: analytics.totalOpens,
                totalClicks: analytics.totalClicks,
                totalBounces: analytics.totalBounces
              }
            };
          }
          return c;
        })
      );
    } catch (err) {
      console.error('Error fetching campaign analytics:', err);
      showNotification('Failed to load analytics', 'error');
    } finally {
      setAnalyticsLoading(false);
    }
  };

  // Reset campaign form
  const resetCampaignForm = () => {
    setCampaignForm({
      name: '',
      templateId: '',
      segmentId: '',
      trackOpens: true,
      trackClicks: true
    });
  };

  // Open campaign edit dialog
  const handleEditCampaign = (campaign) => {
    setCurrentCampaign(campaign);
    setCampaignForm({
      name: campaign.name,
      templateId: campaign.templateId,
      segmentId: campaign.segmentId,
      trackOpens: campaign.trackOpens,
      trackClicks: campaign.trackClicks
    });
    setCreateDialogOpen(true);
  };

  // Update an existing campaign
  const handleUpdateCampaign = async () => {
    if (!currentCampaign) return;
    
    try {
      const response = await apiService.admin.updateEmailCampaign(currentCampaign.id, campaignForm);
      
      // Update the campaign in the local state
      setCampaigns(prev => 
        prev.map(campaign => 
          campaign.id === response.id ? response : campaign
        )
      );
      
      setCreateDialogOpen(false);
      resetCampaignForm();
      setCurrentCampaign(null);
      showNotification('Campaign updated successfully', 'success');
    } catch (err) {
      console.error('Error updating campaign:', err);
      showNotification('Failed to update campaign', 'error');
    }
  };

  // Open schedule dialog
  const handleOpenScheduleDialog = (campaign) => {
    // Check if the campaign is in DRAFT status
    if (campaign.status !== 'DRAFT') {
      showNotification('Only campaigns in DRAFT status can be scheduled', 'error');
      return;
    }
    
    setCurrentCampaign(campaign);
    
    // Set default time to 1 hour from now, rounded to the next 5-minute mark
    const now = new Date();
    const defaultTime = new Date(now.getTime() + 3600000); // 1 hour from now
    const minutes = defaultTime.getMinutes();
    const roundedMinutes = Math.ceil(minutes / 5) * 5;
    defaultTime.setMinutes(roundedMinutes);
    defaultTime.setSeconds(0);
    defaultTime.setMilliseconds(0);
    
    setScheduledTime(defaultTime);
    setScheduleDialogOpen(true);
  };
  
  // Open test email dialog
  const handleOpenTestEmailDialog = (campaign) => {
    setCurrentCampaign(campaign);
    setTestEmail('');
    setTestEmailDialogOpen(true);
  };
  
  // Duplicate a campaign (for CANCELLED or FAILED campaigns)
  const handleDuplicateCampaign = (campaign) => {
    // Create a new campaign form based on the existing campaign
    setCampaignForm({
      name: `${campaign.name} (Copy)`,
      templateId: campaign.templateId,
      segmentId: campaign.segmentId,
      trackOpens: campaign.trackOpens,
      trackClicks: campaign.trackClicks
    });
    
    // Reset current campaign since we're creating a new one
    setCurrentCampaign(null);
    
    // Open the create dialog
    setCreateDialogOpen(true);
  };

  // Show notification
  const showNotification = (message, severity = 'success') => {
    setNotification({
      open: true,
      message,
      severity
    });
  };

  // Close notification
  const handleCloseNotification = () => {
    setNotification(prev => ({
      ...prev,
      open: false
    }));
  };
  
  // Helper to get segment name by ID
  const getSegmentName = (segmentId) => {
    const segment = segments.find(s => s.id === segmentId);
    return segment ? segment.name : 'Unknown Segment';
  };
  
  // Helper to get template name by ID
  const getTemplateName = (templateId) => {
    const template = templates.find(t => t.id === templateId);
    return template ? template.name : 'Unknown Template';
  };

  return (
    <Box>
      {/* Notification Snackbar */}
      <Snackbar 
        open={notification.open} 
        autoHideDuration={6000} 
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseNotification} 
          severity={notification.severity} 
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
      
      {/* Page Header */}
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" sx={{ fontWeight: 500 }}>
          Email Campaigns
        </Typography>
        
        <Button 
          variant="contained" 
          startIcon={<AddIcon />}
          onClick={() => {
            resetCampaignForm();
            setCurrentCampaign(null);
            setCreateDialogOpen(true);
          }}
          sx={{ 
            background: colors.gradient,
            boxShadow: 'none',
            '&:hover': {
              boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
            }
          }}
        >
          Create Campaign
        </Button>
      </Box>
      
      {/* Error Alert */}
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}
      
      {/* Campaign Tabs and Listing */}
      <Paper sx={{ borderRadius: 2, boxShadow: '0 2px 10px rgba(0,0,0,0.05)', mb: 3 }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange} 
          sx={{ 
            borderBottom: 1, 
            borderColor: 'divider',
            px: 2,
            '& .MuiTab-root': {
              textTransform: 'none',
              fontWeight: 500,
              minWidth: 100
            },
            '& .Mui-selected': {
              color: colors.primary,
            },
            '& .MuiTabs-indicator': {
              backgroundColor: colors.primary,
            }
          }}
        >
          <Tab label="All Campaigns" />
          <Tab label="Drafts" />
          <Tab label="Scheduled" />
          <Tab label="Completed" />
        </Tabs>
        
        <Box sx={{ p: 3 }}>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
              <CircularProgress />
            </Box>
          ) : filteredCampaigns.length > 0 ? (
            <Box>
              {filteredCampaigns.map((campaign) => (
                <CampaignCard 
                  key={campaign.id} 
                  campaign={campaign}
                  colors={colors}
                  getSegmentName={getSegmentName}
                  getTemplateName={getTemplateName}
                  onSchedule={handleOpenScheduleDialog}
                  onEdit={handleEditCampaign}
                  onTest={handleOpenTestEmailDialog}
                  onCancel={(campaign) => {
                    setCurrentCampaign(campaign);
                    setCancelConfirmDialogOpen(true);
                  }}
                  onViewAnalytics={handleViewAnalytics}
                  onDuplicate={handleDuplicateCampaign}
                />
              ))}
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <Typography variant="body1" color="text.secondary">
                No campaigns found.
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
      
      {/* Campaign Form Dialog */}
      <CampaignForm
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        campaignForm={campaignForm}
        onFormChange={handleFormChange}
        onSubmit={currentCampaign ? handleUpdateCampaign : handleCreateCampaign}
        isEdit={!!currentCampaign}
        templates={templates}
        segments={segments}
        colors={colors}
      />
      
      {/* Schedule Campaign Dialog */}
      <ScheduleDialog
        open={scheduleDialogOpen}
        onClose={() => setScheduleDialogOpen(false)}
        campaign={currentCampaign}
        scheduledTime={scheduledTime}
        onScheduledTimeChange={(newValue) => setScheduledTime(newValue)}
        onSchedule={handleScheduleCampaign}
        getSegmentName={getSegmentName}
        colors={colors}
      />
      
      {/* Test Email Dialog */}
      <TestEmailDialog
        open={testEmailDialogOpen}
        onClose={() => setTestEmailDialogOpen(false)}
        testEmail={testEmail}
        onTestEmailChange={(value) => setTestEmail(value)}
        onSendTest={handleSendTestEmail}
        colors={colors}
      />
      
      {/* Cancel Confirmation Dialog */}
      <CancelConfirmDialog
        open={cancelConfirmDialogOpen}
        onClose={() => setCancelConfirmDialogOpen(false)}
        onConfirmCancel={handleCancelCampaign}
      />
      
      {/* Analytics Dialog */}
      <AnalyticsDialog
        open={analyticsDialogOpen}
        onClose={() => setAnalyticsDialogOpen(false)}
        campaignAnalytics={campaignAnalytics}
        loading={analyticsLoading}
        formatDate={formatDateWithTime}
      />
    </Box>
  );
};

export default EmailCampaignsPage;