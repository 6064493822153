/**
 * API service for image upload operations
 */
/**
 * Fetch existing images from the Google CDN via backend
 * @returns {Promise<Array<ImageInfo>>} List of available images with metadata
 * 
 * Expected ImageInfo structure:
 * {
 *   filename: string,
 *   url: string,
 *   contentType: string,
 *   size: number,
 *   createTime: number,
 *   updateTime: number
 * }
 */
export const fetchExistingImages = async () => {
  try {
    // API endpoint for listing images
    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';
    const listEndpoint = `${API_URL}/admin/email/templates/images/list`;
    
    // Get auth token from storage (assuming this is how your auth is set up)
    const token = localStorage.getItem('admin_token') || '';
    
    // Send request to server
    const response = await fetch(listEndpoint, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    
    // Check if response is OK
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText || `Server error: ${response.status}`);
    }
    
    // Parse JSON response
    const data = await response.json();
    
    // Check for success flag from backend
    if (!data.success) {
      throw new Error(data.message || 'Failed to fetch images');
    }
    
    // Return the list of images, ensuring we have the expected format
    return Array.isArray(data.images) ? data.images : [];
  } catch (error) {
    console.error('Error fetching existing images:', error);
    throw error;
  }
};

/**
 * Delete an image from Google CDN
 * @param {string} filename - The filename of the image to delete
 * @returns {Promise<Object>} Response object with success status
 */
export const deleteImage = async (filename) => {
  try {
    if (!filename) {
      throw new Error('Filename is required for deletion');
    }
    
    // API endpoint for image deletion
    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';
    const deleteEndpoint = `${API_URL}/admin/email/templates/images/delete/${encodeURIComponent(filename)}`;
    
    // Get auth token from storage
    const token = localStorage.getItem('admin_token') || '';
    
    // Send request to server
    const response = await fetch(deleteEndpoint, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    
    // Check if response is OK
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText || `Server error: ${response.status}`);
    }
    
    // Parse JSON response
    const data = await response.json();
    
    // Check for success flag from backend
    if (!data.success) {
      throw new Error(data.message || 'Failed to delete image');
    }
    
    return data;
  } catch (error) {
    console.error('Error deleting image:', error);
    throw error;
  }
};

/**
 * Upload an image to Google CDN
 * @param {File} file - The image file to upload
 * @returns {Promise<Object>} The uploaded image data (url, name, size, type)
 */
export const uploadImage = async (file) => {
  try {
    // Client-side validation
    if (file.size > 5 * 1024 * 1024) {
      throw new Error('File size exceeds 5MB limit');
    }
    
    // Step 1: Get a signed upload URL from our backend
    // Prepare request for signed URL
    const generateUrlRequest = {
      filename: file.name,
      contentType: file.type
    };
    
    // API endpoint for generating signed upload URL
    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';
    
    // Get auth token from storage
    const token = localStorage.getItem('admin_token') || '';
    
    // Request signed URL from server
    const signedUrlResponse = await fetch(`${API_URL}/admin/email/templates/images/get-upload-url`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(generateUrlRequest)
    });
    
    // Check if response is OK
    if (!signedUrlResponse.ok) {
      const errorText = await signedUrlResponse.text();
      throw new Error(errorText || `Failed to get upload URL: ${signedUrlResponse.status}`);
    }
    
    // Parse JSON response
    const signedUrlData = await signedUrlResponse.json();
    
    if (!signedUrlData.success || !signedUrlData.uploadUrl) {
      throw new Error(signedUrlData.message || 'Failed to generate upload URL');
    }
    
    // Step 2: Upload the file directly to Google CDN using the signed URL
    const uploadResponse = await fetch(signedUrlData.uploadUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type
      },
      body: file
    });
    
    if (!uploadResponse.ok) {
      throw new Error(`Failed to upload to Google CDN: ${uploadResponse.status}`);
    }
    
    // The image URL should be provided in the initial response
    const imageUrl = signedUrlData.imageUrl;
    
    if (!imageUrl) {
      throw new Error('No image URL returned from server');
    }
    
    // Return data with the CDN URL
    return {
      url: imageUrl,
      name: signedUrlData.filename || file.name,
      size: file.size,
      type: file.type
    };
  } catch (error) {
    console.error('Image upload error:', error);
    throw error;
  }
};