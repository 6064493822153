import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Typography, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem
} from '@mui/material';
import { Translate as TranslateIcon } from '@mui/icons-material';
import { colors } from '../../../admin/utils/constants';

/**
 * Dialog for selecting a language to add to the template
 */
const TranslateDialog = ({ open, onClose, onTranslate, existingLanguages = ['en'] }) => {
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [error, setError] = useState('');
  
  // All available languages
  const allLanguages = [
    { code: 'en-GB', name: 'English (UK)' },
    { code: 'en-AU', name: 'English (Australia)' },
    { code: 'en-CA', name: 'English (Canada)' },
    { code: 'en-NZ', name: 'English (New Zealand)' },
    { code: 'en-IE', name: 'English (Ireland)' },
    { code: 'en-ZA', name: 'English (South Africa)' },
    { code: 'es', name: 'Spanish (Spain)' },
    { code: 'es-MX', name: 'Spanish (Mexico)' },
    { code: 'es-AR', name: 'Spanish (Argentina)' },
    { code: 'es-CO', name: 'Spanish (Colombia)' },
    { code: 'es-CL', name: 'Spanish (Chile)' },
    { code: 'es-PE', name: 'Spanish (Peru)' },
    { code: 'fr', name: 'French' },
    { code: 'fr-CA', name: 'French (Canada)' },
    { code: 'fr-BE', name: 'French (Belgium)' },
    { code: 'fr-CH', name: 'French (Switzerland)' },
    { code: 'de', name: 'German' },
    { code: 'de-AT', name: 'German (Austria)' },
    { code: 'de-CH', name: 'German (Switzerland)' },
    { code: 'it', name: 'Italian' },
    { code: 'ja', name: 'Japanese' },
    { code: 'zh', name: 'Chinese (Simplified)' },
    { code: 'zh-TW', name: 'Chinese (Traditional)' },
    { code: 'ru', name: 'Russian' },
    { code: 'pt', name: 'Portuguese (Portugal)' },
    { code: 'pt-BR', name: 'Portuguese (Brazil)' },
    { code: 'ar', name: 'Arabic' }
  ];
  
  // Filter out languages that already exist in the template
  const availableLanguages = allLanguages.filter(
    lang => !existingLanguages.includes(lang.code)
  );
  
  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
    setError('');
  };
  
  const handleAddLanguage = () => {
    if (!selectedLanguage) {
      setError('Please select a language');
      return;
    }
    
    // Add the language to the template without translation
    onTranslate(selectedLanguage);
    onClose();
  };
  
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add New Language</DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
          Select a language to add to this template. After adding the language, 
          you can select it from the language dropdown and use the "Translate from Default" 
          button when ready to translate.
        </Typography>
        
        {availableLanguages.length === 0 ? (
          <Typography color="warning.main" variant="body2" sx={{ mb: 2 }}>
            All available languages have already been added. No additional languages are available.
          </Typography>
        ) : (
          <FormControl fullWidth error={!!error}>
            <InputLabel id="language-select-label">Target Language</InputLabel>
            <Select
              labelId="language-select-label"
              value={selectedLanguage}
              onChange={handleLanguageChange}
              label="Target Language"
            >
              {availableLanguages.length === 0 ? (
                <MenuItem disabled>
                  <em>No languages available to add</em>
                </MenuItem>
              ) : (
                availableLanguages.map((language) => (
                  <MenuItem key={language.code} value={language.code}>
                    {language.name}
                  </MenuItem>
                ))
              )}
            </Select>
            {error && <Typography color="error" variant="caption">{error}</Typography>}
          </FormControl>
        )}
        
        <Typography variant="body2" color="text.secondary" sx={{ mt: 3 }}>
          <strong>Note:</strong> Translations are generated using AI and may require review for accuracy, 
          especially for industry-specific terminology.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleAddLanguage} 
          variant="contained"
          startIcon={<TranslateIcon />}
          disabled={availableLanguages.length === 0 || !selectedLanguage}
          sx={{ 
            background: colors.gradient,
            boxShadow: 'none'
          }}
        >
          Add Language
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TranslateDialog;