// API service configuration
import { getStorageItem } from '../utils/storage';
import { apiMockUsers, mockEmailTemplates } from '../admin/data/mockData';

// Helper function to extract variables from template content
const extractVariables = (content) => {
  if (!content) return [];
  
  // Extract all variables with the pattern {{variable_name}}
  const variableRegex = /{{([^{}]+)}}/g;
  const matches = content.match(variableRegex) || [];
  
  // Extract variable names and remove whitespace
  const variables = matches.map(match => {
    const variable = match.slice(2, -2).trim();
    return variable;
  });
  
  // Remove duplicates
  return [...new Set(variables)];
};

// Base API URLs
// In production, if REACT_APP_API_URL isn't set, use relative URLs (same domain)
// In development, use 127.0.0.1 instead of 0.0.0.0 to avoid URL scheme issues
const API_BASE_URL = process.env.REACT_APP_API_URL || 
  (process.env.NODE_ENV === 'production' ? '' : 'http://0.0.0.0:8080');

// Ensure the URL has a protocol if it's not a relative URL
function ensureProtocol(url) {
  // If it's a relative URL (starts with /), return as is
  if (url === '' || url.startsWith('/')) {
    return url;
  }
  
  // If it already has a protocol, return as is
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }
  
  // Replacel localhost with 127.0.0.1 to avoid URL scheme issues
  let fixedUrl = url;
  if (fixedUrl.startsWith('localhost')) {
    fixedUrl = fixedUrl.replace('localhost', '0.0.0.0');
  }
  
  // Add protocol if needed
  return `http://${fixedUrl}`;
}

// For development with CORS issues, we can use mock data
// Use an environment variable or set manually for toggling between mock/real API
// Set this to true to use mock data, false to use real API endpoints
const USE_MOCK_DATA = false; // Use mock data for local testing

// API endpoints
const ENDPOINTS = {
  // Auth endpoints
  AUTH_GOOGLE: '/auth-google-web',
  VERIFY_2FA: '/verify-2fa',
  SETUP_2FA: '/setup-2fa',
  CONFIRM_2FA_SETUP: '/confirm-2fa-setup',
  
  // Admin user management endpoints
  ADMIN_USERS: '/admin/users',
  ADMIN_USERS_COUNT: '/admin/users/count',
  ADMIN_USERS_METADATA: '/admin/users/metadata',
  
  // Admin segment management endpoints
  ADMIN_SEGMENTS: '/admin/segments',
  
  // Admin email template endpoints
  ADMIN_EMAIL_TEMPLATES: '/admin/email/templates',
  ADMIN_EMAIL_TEMPLATES_VALIDATE: '/admin/email/templates/validate',
  ADMIN_EMAIL_TEMPLATES_TEST: '/admin/email/templates/test',
  ADMIN_EMAIL_TEMPLATES_ENHANCE: '/admin/email/templates/enhance',
  ADMIN_EMAIL_TEMPLATES_TRANSLATE: '/admin/email/templates/translate',
  ADMIN_EMAIL_TEMPLATES_IMAGE_UPLOAD: '/admin/email/templates/images/get-upload-url',
  ADMIN_EMAIL_TEMPLATES_IMAGES_LIST: '/admin/email/templates/images/list',
  ADMIN_EMAIL_TEMPLATES_IMAGES_DELETE: '/admin/email/templates/images/delete',
  
  // Admin email campaign endpoints
  ADMIN_EMAIL_CAMPAIGNS: '/admin/email/campaigns',
  ADMIN_EMAIL_CAMPAIGN_SCHEDULE: '/admin/email/campaigns/:id/schedule',
  ADMIN_EMAIL_CAMPAIGN_CANCEL: '/admin/email/campaigns/:id/cancel',
  ADMIN_EMAIL_CAMPAIGN_TEST: '/admin/email/campaigns/:id/test',
  ADMIN_EMAIL_CAMPAIGN_ANALYTICS: '/admin/email/campaigns/:id/analytics',
};

// Standard fetch options
const defaultOptions = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
};

// Request deduplication system
const pendingRequests = new Map();

// Helper to create a unique request key
const createRequestKey = (endpoint, options) => {
  return `${options.method || 'GET'}-${endpoint}-${options.body || ''}`;
};

// Helper to add auth token to requests if available
const addAuthHeader = (options = {}) => {
  const token = getStorageItem('admin_token');
  
  if (token) {
    return {
      ...options,
      headers: {
        ...options.headers,
        'Authorization': `Bearer ${token}`
      }
    };
  }
  
  return options;
};

// Generic request function with deduplication
const apiRequest = async (endpoint, options = {}) => {
  try {
    const baseUrl = ensureProtocol(API_BASE_URL);
    let url = `${baseUrl}${endpoint}`;
    
    // Log URL in development mode for debugging
    if (process.env.NODE_ENV === 'development') {
      console.log('API Request URL:', url);
      console.log('API Base URL:', baseUrl);
      console.log('Endpoint:', endpoint);
    }
    
    const requestKey = createRequestKey(endpoint, options);
    
    // Check if we already have a pending request for this exact same operation
    if (pendingRequests.has(requestKey)) {
      return pendingRequests.get(requestKey);
    }
    
    // Update fetch options with CORS settings
    const fetchOptions = {
      ...options,
      mode: 'cors',
      // Use 'same-origin' for production (more secure, less CORS issues)
      // Use 'include' for development (allows cross-domain cookies)
      credentials: process.env.NODE_ENV === 'production' ? 'same-origin' : 'include',
      headers: {
        ...options.headers,
        'Accept': 'application/json'
      }
    };
    
    // Create a promise for this request
    const requestPromise = (async () => {
      try {
        // Simulate a successful response with mock data when in development mode
        if (process.env.NODE_ENV === 'development' && USE_MOCK_DATA) {
          console.log('%c[MOCK DATA]%c Using mock data for API request: %c' + endpoint, 
                      'background: #FFC107; color: black; font-weight: bold; padding: 2px 4px; border-radius: 2px;', 
                      'color: gray;', 
                      'color: blue; font-weight: bold;');
          // This is a development-only approach to bypass backend requirements
          
          // If this is a metadata request
          if (endpoint === ENDPOINTS.ADMIN_USERS_METADATA) {
            console.log('Simulating metadata API response');
            // Return mock metadata
            return {
              countries: ["US", "UK", "DE", "FR", "ES", "IT"],
              languages: ["en", "es", "fr", "de", "it"],
              subscriptionStatuses: ["subscribed", "expired", "never-purchased"]
            };
          }
          
          // If this is a users request
          if (endpoint.startsWith(ENDPOINTS.ADMIN_USERS) && !endpoint.includes('count')) {
            console.log('Simulating users API response');
            // Extract pagination params from URL
            const urlParams = new URL(url, 'http://dummy.com');
            const page = parseInt(urlParams.searchParams.get('page') || '1');
            const limit = parseInt(urlParams.searchParams.get('limit') || '10');
            // These parameters are extracted but not used in the mock implementation
            // They would be used for sorting in a real API implementation
            // const sort = urlParams.searchParams.get('sort') || 'registrationDate';
            // const order = urlParams.searchParams.get('order') || 'desc';
            
            // Use mock users from the imported data
            const mockUsers = apiMockUsers;
          
            // Return paginated mock data
            const startIndex = (page - 1) * limit;
            const endIndex = startIndex + limit;
            const paginatedUsers = mockUsers.slice(startIndex, endIndex);
            
            return {
              data: paginatedUsers,
              pagination: {
                total: mockUsers.length,
                page: page,
                limit: limit,
                pages: Math.ceil(mockUsers.length / limit)
              }
            };
          }
          
          // If this is a segment creation request
          if (endpoint === ENDPOINTS.ADMIN_SEGMENTS && options.method === 'POST') {
            console.log('Simulating segment creation');
            // Parse the request body
            const segmentData = JSON.parse(options.body);
            
            // Return a mock created segment
            return {
              id: "segment" + Date.now(),
              ...segmentData,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              userCount: 42 // Mock count
            };
          }
          
          // If this is an email campaigns request
          if (endpoint === ENDPOINTS.ADMIN_EMAIL_CAMPAIGNS) {
            console.log('Simulating email campaigns API response');
            
            // If this is a GET request for all campaigns
            if (options.method === 'GET' || !options.method) {
              if (endpoint.includes('/analytics')) {
                // This is a request for campaign analytics
                const campaignId = endpoint.split('/').slice(-2)[0];
                console.log('GET campaign analytics for:', campaignId);
                
                return {
                  campaignId: campaignId,
                  name: "Welcome Campaign",
                  totalRecipients: 1250,
                  totalSent: 1250,
                  totalOpens: 750,
                  totalClicks: 320,
                  totalBounces: 15,
                  totalComplaints: 2,
                  totalUnsubscribes: 5,
                  openRate: 60.0,
                  clickRate: 25.6,
                  bounceRate: 1.2,
                  complaintRate: 0.16,
                  unsubscribeRate: 0.4,
                  sentAt: Date.now() - 86400000, // yesterday
                  deviceBreakdown: {
                    desktop: 350,
                    mobile: 325,
                    tablet: 75,
                    other: 0,
                    desktopPercentage: 46.67,
                    mobilePercentage: 43.33,
                    tabletPercentage: 10.0,
                    otherPercentage: 0.0
                  },
                  mostClickedLinks: [
                    {
                      url: "https://example.com/login",
                      clicks: 180,
                      clickRate: 56.25
                    },
                    {
                      url: "https://example.com/get-started",
                      clicks: 120,
                      clickRate: 37.5
                    },
                    {
                      url: "https://example.com/help",
                      clicks: 20,
                      clickRate: 6.25
                    }
                  ]
                };
              } else if (endpoint.includes('/')) {
                // This is a request for a specific campaign
                const campaignId = endpoint.split('/').pop();
                console.log('GET specific campaign:', campaignId);
                
                // Create a mock campaign based on the ID
                return {
                  id: campaignId,
                  name: "Welcome Campaign",
                  templateId: "template-uuid-here",
                  segmentId: "segment-uuid-here",
                  status: "SCHEDULED",
                  scheduledTime: Date.now() + 3600000, // 1 hour from now
                  trackOpens: true,
                  trackClicks: true,
                  createdBy: "admin-uuid-here",
                  createdAt: Date.now() - 86400000, // yesterday
                  updatedAt: Date.now() - 43200000  // 12 hours ago
                };
              } else {
                // This is a request for all campaigns
                console.log('GET all campaigns');
                
                // Create mock campaigns
                const mockCampaignsList = [
                  {
                    id: "campaign-1",
                    name: "Welcome Campaign",
                    templateId: "template-uuid-1",
                    segmentId: "segment-uuid-1",
                    status: "DRAFT",
                    trackOpens: true,
                    trackClicks: true,
                    createdBy: "admin-uuid-here",
                    createdAt: Date.now() - 86400000, // yesterday
                    updatedAt: Date.now() - 43200000  // 12 hours ago
                  },
                  {
                    id: "campaign-2",
                    name: "Monthly Newsletter",
                    templateId: "template-uuid-2",
                    segmentId: "segment-uuid-2",
                    status: "SCHEDULED",
                    scheduledTime: Date.now() + 86400000, // tomorrow
                    trackOpens: true,
                    trackClicks: true,
                    createdBy: "admin-uuid-here",
                    createdAt: Date.now() - 172800000, // 2 days ago
                    updatedAt: Date.now() - 86400000   // yesterday
                  },
                  {
                    id: "campaign-3",
                    name: "Product Update",
                    templateId: "template-uuid-3",
                    segmentId: "segment-uuid-3",
                    status: "COMPLETED",
                    trackOpens: true,
                    trackClicks: true,
                    createdBy: "admin-uuid-here",
                    createdAt: Date.now() - 259200000, // 3 days ago
                    updatedAt: Date.now() - 172800000, // 2 days ago
                    sentAt: Date.now() - 172800000,    // 2 days ago
                    metrics: {
                      openRate: 68.5,
                      clickRate: 42.3,
                      bounceRate: 1.8,
                      totalRecipients: 1250,
                      totalOpens: 856,
                      totalClicks: 529,
                      totalBounces: 22
                    }
                  },
                  {
                    id: "campaign-4",
                    name: "Failed Campaign Example",
                    templateId: "template-uuid-2",
                    segmentId: "segment-uuid-3",
                    status: "FAILED",
                    trackOpens: true,
                    trackClicks: true,
                    createdBy: "admin-uuid-here",
                    createdAt: Date.now() - 345600000, // 4 days ago
                    updatedAt: Date.now() - 259200000  // 3 days ago
                  },
                  {
                    id: "campaign-5",
                    name: "Cancelled Campaign Example",
                    templateId: "template-uuid-1",
                    segmentId: "segment-uuid-2",
                    status: "CANCELLED",
                    trackOpens: true,
                    trackClicks: true,
                    createdBy: "admin-uuid-here",
                    createdAt: Date.now() - 432000000, // 5 days ago
                    updatedAt: Date.now() - 345600000  // 4 days ago
                  }
                ];
                
                return {
                  campaigns: mockCampaignsList
                };
              }
            } else if (options.method === 'POST') {
              if (endpoint.includes('/schedule')) {
                // Scheduling a campaign
                const campaignId = endpoint.split('/').slice(-2)[0];
                const scheduleData = JSON.parse(options.body);
                
                console.log('POST schedule campaign:', campaignId, scheduleData);
                
                return {
                  id: campaignId,
                  status: "SCHEDULED",
                  scheduledTime: scheduleData.scheduledTime,
                  name: "Welcome Campaign",
                  subject: "Welcome to our platform",
                  templateId: "template-uuid-here",
                  segmentId: "segment-uuid-here",
                  trackOpens: true,
                  trackClicks: true,
                  createdBy: "admin-uuid-here",
                  createdAt: Date.now() - 86400000, // yesterday
                  updatedAt: Date.now()             // now
                };
              } else if (endpoint.includes('/cancel')) {
                // Cancelling a campaign
                const campaignId = endpoint.split('/').slice(-2)[0];
                
                console.log('POST cancel campaign:', campaignId);
                
                return {
                  id: campaignId,
                  status: "CANCELLED",
                  name: "Welcome Campaign",
                  subject: "Welcome to our platform",
                  templateId: "template-uuid-here",
                  segmentId: "segment-uuid-here",
                  trackOpens: true,
                  trackClicks: true,
                  createdBy: "admin-uuid-here",
                  createdAt: Date.now() - 86400000, // yesterday
                  updatedAt: Date.now()             // now
                };
              } else if (endpoint.includes('/test')) {
                // Sending a test email
                const campaignId = endpoint.split('/').slice(-2)[0];
                const testData = JSON.parse(options.body);
                
                console.log('POST test campaign email:', campaignId, testData);
                
                return {
                  success: true,
                  message: `Test email successfully sent to ${testData.receiverEmail}`
                };
              } else {
                // Creating a new campaign
                const campaignData = JSON.parse(options.body);
                
                console.log('POST create campaign:', campaignData);
                
                return {
                  id: "new-campaign-" + Date.now(),
                  status: "DRAFT",
                  ...campaignData,
                  createdBy: "admin-uuid-here",
                  createdAt: Date.now(),
                  updatedAt: Date.now()
                };
              }
            } else if (options.method === 'PUT') {
              // Updating a campaign
              const campaignId = endpoint.split('/').pop();
              const campaignData = JSON.parse(options.body);
              
              console.log('PUT update campaign:', campaignId, campaignData);
              
              return {
                id: campaignId,
                status: "DRAFT",
                ...campaignData,
                createdBy: "admin-uuid-here",
                createdAt: Date.now() - 86400000, // yesterday
                updatedAt: Date.now()             // now
              };
            }
          }
        
          // If this is an email templates request
          if (endpoint === ENDPOINTS.ADMIN_EMAIL_TEMPLATES) {
            console.log('Simulating email templates API response');
            
            // If this is a GET request for all templates
            if (options.method === 'GET' || !options.method) {
              // Extract pagination params from URL
              const urlParams = new URL(url, 'http://dummy.com');
              const page = parseInt(urlParams.searchParams.get('page') || '1');
              const limit = parseInt(urlParams.searchParams.get('limit') || '10');
              const search = urlParams.searchParams.get('search') || '';
              
              console.log('GET email templates with params:', { page, limit, search });
              
              // Clone the templates to avoid modifying the original data
              let filteredTemplates = JSON.parse(JSON.stringify(mockEmailTemplates));
              
              // Ensure all templates have defaultContent
              filteredTemplates = filteredTemplates.map(template => {
                if (!template.defaultContent) {
                  console.log('Adding defaultContent to template:', template.id);
                  return {
                    ...template,
                    defaultContent: {
                      language: 'en',
                      subject: template.subject || '',
                      htmlContent: template.htmlContent || '',
                      textContent: template.textContent || ''
                    }
                  };
                }
                return template;
              });
              
              if (search) {
                const searchLower = search.toLowerCase();
                filteredTemplates = filteredTemplates.filter(template => 
                  template.name.toLowerCase().includes(searchLower) || 
                  template.description.toLowerCase().includes(searchLower)
                );
              }
              
              // Return paginated mock data
              const startIndex = (page - 1) * limit;
              const endIndex = startIndex + limit;
              const paginatedTemplates = filteredTemplates.slice(startIndex, endIndex);
              
              // Output the structure of the first template for debugging
              if (paginatedTemplates.length > 0) {
                console.log('First template structure:', JSON.stringify({
                  id: paginatedTemplates[0].id,
                  name: paginatedTemplates[0].name,
                  description: paginatedTemplates[0].description,
                  hasDefaultContent: !!paginatedTemplates[0].defaultContent,
                  defaultContentKeys: paginatedTemplates[0].defaultContent ? Object.keys(paginatedTemplates[0].defaultContent) : []
                }, null, 2));
              }
              
              // This is the final response structure
              const response = {
                templates: paginatedTemplates,
                total: filteredTemplates.length
              };
              
              console.log('Response structure:', JSON.stringify({
                hasTemplatesArray: Array.isArray(response.templates),
                templatesCount: response.templates.length,
                totalCount: response.total
              }, null, 2));
              
              return response;
            }
            
            // If this is a POST request to create a template
            if (options.method === 'POST') {
              const templateData = JSON.parse(options.body);
              
              // Ensure templateData has defaultContent
              if (!templateData.defaultContent) {
                templateData.defaultContent = {
                  language: 'en',
                  subject: templateData.subject || '',
                  htmlContent: templateData.htmlContent || '',
                  textContent: templateData.textContent || ''
                };
              }
              
              // Handle new API structure with defaultContent
              let variables = [];
              if (templateData.defaultContent && templateData.defaultContent.htmlContent) {
                variables = extractVariables(templateData.defaultContent.htmlContent);
              }
              
              const newTemplate = {
                id: "template" + Date.now(),
                ...templateData,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                variables: variables
              };
              
              return newTemplate;
            }
          }
          
          // If this is a specific template request
          if (endpoint.startsWith(ENDPOINTS.ADMIN_EMAIL_TEMPLATES) && 
              endpoint !== ENDPOINTS.ADMIN_EMAIL_TEMPLATES &&
              endpoint !== ENDPOINTS.ADMIN_EMAIL_TEMPLATES_VALIDATE && 
              endpoint !== ENDPOINTS.ADMIN_EMAIL_TEMPLATES_TEST) {
            
            // Extract template ID from the endpoint
            const templateId = endpoint.split('/').pop();
            console.log('GET template by ID:', templateId);
            
            // Find the template but create a clone to avoid modifying the original
            const originalTemplate = mockEmailTemplates.find(t => t.id === templateId);
            
            if (!originalTemplate) {
              throw new Error('Template not found');
            }
            
            // Clone the template to avoid modifying the original
            const template = JSON.parse(JSON.stringify(originalTemplate));
            
            // Ensure template has defaultContent
            if (!template.defaultContent) {
              console.log('Adding defaultContent to template:', template.id);
              template.defaultContent = {
                language: 'en',
                subject: template.subject || '',
                htmlContent: template.htmlContent || '',
                textContent: template.textContent || ''
              };
            }
            
            console.log('Returning template:', template);
            
            // If this is a GET request
            if (options.method === 'GET' || !options.method) {
              return template;
            }
            
            // If this is a PUT request to update the template
            if (options.method === 'PUT') {
              const templateData = JSON.parse(options.body);
              
              // Ensure templateData has defaultContent
              if (!templateData.defaultContent) {
                templateData.defaultContent = {
                  language: 'en',
                  subject: templateData.subject || '',
                  htmlContent: templateData.htmlContent || '',
                  textContent: templateData.textContent || ''
                };
              }
              
              // Handle new API structure with defaultContent
              let variables = [];
              if (templateData.defaultContent && templateData.defaultContent.htmlContent) {
                variables = extractVariables(templateData.defaultContent.htmlContent);
              } else if (template.defaultContent && template.defaultContent.htmlContent) {
                variables = extractVariables(template.defaultContent.htmlContent);
              }
              
              const updatedTemplate = {
                ...template,
                ...templateData,
                updatedAt: new Date().toISOString(),
                variables: variables
              };
              
              return updatedTemplate;
            }
            
            // If this is a DELETE request
            if (options.method === 'DELETE') {
              return { success: true, message: 'Template deleted successfully' };
            }
          }
          
          // If this is a validate template request
          if (endpoint === ENDPOINTS.ADMIN_EMAIL_TEMPLATES_VALIDATE) {
            const templateData = JSON.parse(options.body);
            let variables = [];
            
            // Handle new API structure with defaultContent
            if (templateData.defaultContent && templateData.defaultContent.htmlContent) {
              variables = extractVariables(templateData.defaultContent.htmlContent);
            } else if (templateData.htmlContent) {
              variables = extractVariables(templateData.htmlContent);
            }
            
            return {
              isValid: true,
              variables,
              errors: []
            };
          }
          
          // If this is a test template request
          if (endpoint === ENDPOINTS.ADMIN_EMAIL_TEMPLATES_TEST) {
            const requestData = JSON.parse(options.body);
            
            console.log('Received test template request:', JSON.stringify(requestData, null, 2));
            
            // Check if receiverEmail is provided (now required)
            if (!requestData.receiverEmail) {
              throw new Error('Recipient email is required');
            }
            
            // Extract content from the new structure
            const content = requestData.content || {};
            
            return {
              success: true,
              message: `Test email successfully sent to ${requestData.receiverEmail}`,
              subject: content.subject || '',
              htmlContent: content.htmlContent || '',
              textContent: content.textContent || '',
              variables: extractVariables(content.htmlContent || '')
            };
          }
          
          // If this is an enhance template request
          if (endpoint === ENDPOINTS.ADMIN_EMAIL_TEMPLATES_ENHANCE) {
            const requestData = JSON.parse(options.body);
            const content = requestData.content || {};
            const originalHtml = content.htmlContent || '';
            
            console.log("MOCK API: Processing enhance request with HTML length:", originalHtml.length);
            
            // Mock enhanced HTML with some common email client optimizations
            const enhancedHtml = originalHtml
              // Simulate CSS inlining
              .replace(/<style>(.*?)<\/style>/gs, '')
              .replace(/<div/g, '<div style="margin: 0; padding: 0;"')
              .replace(/<p/g, '<p style="margin: 0; padding: 10px 0;"')
              .replace(/<a/g, '<a style="color: #0000EE; text-decoration: underline;"')
              // Add table-based layout wrapper
              .replace(/<body(.*?)>/i, '<body$1><table cellpadding="0" cellspacing="0" border="0" width="100%" style="width: 100%; max-width: 600px; margin: 0 auto;"><tr><td>')
              .replace(/<\/body>/i, '</td></tr></table></body>');
            
            console.log("MOCK API: Created enhanced HTML with length:", enhancedHtml.length);
            
            const response = {
              success: true,
              message: 'Email template optimized for better compatibility across email clients',
              content: {
                language: content.language || 'en',
                subject: content.subject || '',
                htmlContent: enhancedHtml,
                textContent: content.textContent || ''
              },
              variables: extractVariables(enhancedHtml),
              optimizations: [
                "CSS inlined",
                "Table-based layout applied",
                "Email client specific fixes implemented",
                "Responsive design improvements"
              ]
            };
            
            console.log("MOCK API: Returning enhance response with content.htmlContent length:", 
                      response.content.htmlContent.length);
            
            return response;
          }
          
          // If this is a translate template request
          if (endpoint === ENDPOINTS.ADMIN_EMAIL_TEMPLATES_TRANSLATE) {
            const data = JSON.parse(options.body);
            
            // Handle both new and legacy translation API formats
            if (data.content && data.sourceLanguage && data.targetLanguage) {
              // New API format - single language translation
              const { content, sourceLanguage, targetLanguage } = data;
              
              console.log(`Mock API: Translating content from ${sourceLanguage} to ${targetLanguage}`);
              
              // Simple language-specific content modifications for demonstration
              const languageModifiers = {
                'es': (text) => text.replace('Welcome', '¡Bienvenido')
                                  .replace('Hello', '¡Hola')
                                  .replace('Thank you', 'Gracias'),
                'fr': (text) => text.replace('Welcome', 'Bienvenue')
                                  .replace('Hello', 'Bonjour')
                                  .replace('Thank you', 'Merci'),
                'de': (text) => text.replace('Welcome', 'Willkommen')
                                  .replace('Hello', 'Hallo')
                                  .replace('Thank you', 'Danke'),
                'it': (text) => text.replace('Welcome', 'Benvenuto')
                                  .replace('Hello', 'Ciao')
                                  .replace('Thank you', 'Grazie'),
                'ja': (text) => text.replace('Welcome', 'ようこそ')
                                  .replace('Hello', 'こんにちは')
                                  .replace('Thank you', 'ありがとう'),
                'zh': (text) => text.replace('Welcome', '欢迎')
                                  .replace('Hello', '你好')
                                  .replace('Thank you', '谢谢'),
                'ru': (text) => text.replace('Welcome', 'Добро пожаловать')
                                  .replace('Hello', 'Здравствуйте')
                                  .replace('Thank you', 'Спасибо'),
                'pt': (text) => text.replace('Welcome', 'Bem-vindo')
                                  .replace('Hello', 'Olá')
                                  .replace('Thank you', 'Obrigado'),
                'ar': (text) => text.replace('Welcome', 'مرحبا')
                                  .replace('Hello', 'مرحبا')
                                  .replace('Thank you', 'شكرا لك')
              };
              
              // Simulate a failure for an invalid language (for testing error handling)
              if (targetLanguage === 'xx') {
                return {
                  success: false,
                  message: `Failed to translate to ${targetLanguage}: Unsupported language code`,
                  error: `Unsupported language code: ${targetLanguage}`
                };
              }
              
              const modifier = languageModifiers[targetLanguage] || ((text) => `[${targetLanguage}] ${text}`);
              
              // Create the translated content
              const translatedContent = {
                language: targetLanguage,
                subject: modifier(content.subject || 'Welcome to Parcae'),
                htmlContent: modifier(content.htmlContent || '<p>Hello, {{user.name}}!</p>'),
                textContent: modifier(content.textContent || 'Hello, {{user.name}}!')
              };
              
              return {
                success: true,
                message: `Successfully translated template to ${targetLanguage}`,
                targetLanguage,
                translatedContent
              };
            } else {
              // Legacy API format - multiple languages translation
              const { templateId, targetLanguages } = data;
              
              if (!templateId || !targetLanguages) {
                throw new Error('Invalid translation request: missing required parameters');
              }
              
              // Find the original template to translate
              const template = mockEmailTemplates.find(t => t.id === templateId);
              
              if (!template) {
                throw new Error(`Template not found: ${templateId}`);
              }
              
              // Need to use the new defaultContent structure
              if (!template.defaultContent) {
                throw new Error(`Template missing defaultContent: ${templateId}`);
              }
              
              // Create mock translations
              const translations = {};
              const errors = {};
              
              // Simple language-specific content modifications for demonstration
              const languageModifiers = {
                'es': (text) => text.replace('Welcome', '¡Bienvenido')
                                  .replace('Hello', '¡Hola')
                                  .replace('Thank you', 'Gracias'),
                'fr': (text) => text.replace('Welcome', 'Bienvenue')
                                  .replace('Hello', 'Bonjour')
                                  .replace('Thank you', 'Merci'),
                'de': (text) => text.replace('Welcome', 'Willkommen')
                                  .replace('Hello', 'Hallo')
                                  .replace('Thank you', 'Danke'),
                'it': (text) => text.replace('Welcome', 'Benvenuto')
                                  .replace('Hello', 'Ciao')
                                  .replace('Thank you', 'Grazie'),
                'ja': (text) => text.replace('Welcome', 'ようこそ')
                                  .replace('Hello', 'こんにちは')
                                  .replace('Thank you', 'ありがとう'),
                'zh': (text) => text.replace('Welcome', '欢迎')
                                  .replace('Hello', '你好')
                                  .replace('Thank you', '谢谢'),
                'ru': (text) => text.replace('Welcome', 'Добро пожаловать')
                                  .replace('Hello', 'Здравствуйте')
                                  .replace('Thank you', 'Спасибо'),
                'pt': (text) => text.replace('Welcome', 'Bem-vindo')
                                  .replace('Hello', 'Olá')
                                  .replace('Thank you', 'Obrigado'),
                'ar': (text) => text.replace('Welcome', 'مرحبا')
                                  .replace('Hello', 'مرحبا')
                                  .replace('Thank you', 'شكرا لك')
              };
              
              targetLanguages.forEach(lang => {
                // Simulate a failure for one language (for testing error handling)
                if (lang === 'xx') {
                  errors[lang] = `Failed to translate to ${lang}: Unsupported language code`;
                  return;
                }
                
                const modifier = languageModifiers[lang] || ((text) => `[${lang}] ${text}`);
                
                translations[lang] = {
                  language: lang,
                  subject: modifier(template.defaultContent.subject || 'Welcome to Parcae'),
                  htmlContent: modifier(template.defaultContent.htmlContent || '<p>Hello, {{user.name}}!</p>'),
                  textContent: modifier(template.defaultContent.textContent || 'Hello, {{user.name}}!')
                };
              });
              
              return {
                success: true,
                message: `Successfully translated template to ${Object.keys(translations).length} languages${Object.keys(errors).length > 0 ? `, with ${Object.keys(errors).length} failure(s)` : ''}`,
                translations,
                errors
              };
            }
          }
        }
        
        // Make the actual API request if not simulated
        const response = await fetch(url, fetchOptions);
        
        if (!response.ok) {
          // Try to parse error as JSON first
          let errorText;
          try {
            const errorJson = await response.json();
            // Extract error message from common API response formats
            errorText = errorJson.message || errorJson.error || JSON.stringify(errorJson);
          } catch (e) {
            // Fallback to text if not JSON
            errorText = await response.text();
          }
          throw new Error(`API Error: ${response.status} - ${errorText}`);
        }
        
        const data = await response.json();
        return data;
      } catch (error) {
        // Output more detailed error information
        console.error('API request error:', {
          url,
          endpoint,
          method: options.method || 'GET',
          error: error.message,
          stack: error.stack
        });
        
        // Handle network errors specifically
        if (error.message.includes('Failed to fetch') || error.name === 'TypeError') {
          console.error('Network error or server not running:', url);
          
          // Log the URL being accessed
          console.error(`Failed to access: ${url}`);
          
          // Suggest enabling mock data in development
          if (process.env.NODE_ENV === 'development' && !USE_MOCK_DATA) {
            console.warn('Consider enabling USE_MOCK_DATA for development');
            throw new Error(`Cannot connect to the authentication server at ${url}. To use mock data for development, set USE_MOCK_DATA = true in api.js.`);
          } else {
            throw new Error(`Cannot connect to the authentication server. Please check your network connection or try again later.`);
          }
        }
        throw error;
      } finally {
        // Remove from pending requests map once done
        pendingRequests.delete(requestKey);
      }
    })();
    
    // Store the promise in our pending requests map
    pendingRequests.set(requestKey, requestPromise);
    
    return requestPromise;
  } catch (error) {
    throw error;
  }
};

// Authentication API
export const authApi = {
  /**
   * Authenticate with Google credentials
   * @param {string} credential - Google credential token
   * @returns {Promise<Object>} Authentication result
   */
  googleAuth: (credential) => {
    return apiRequest(ENDPOINTS.AUTH_GOOGLE, {
      ...defaultOptions,
      method: 'POST',
      body: JSON.stringify({ credential })
    });
  },
  
  /**
   * Verify 2FA code
   * @param {string} code - 2FA verification code
   * @param {string} userId - User ID to verify
   * @returns {Promise<Object>} Verification result
   */
  verify2FA: (code, userId) => {
    return apiRequest(ENDPOINTS.VERIFY_2FA, {
      ...defaultOptions,
      method: 'POST',
      body: JSON.stringify({ code, userId })
    });
  },
  
  /**
   * Setup 2FA for a user
   * @param {string} userId - User ID to setup 2FA for
   * @returns {Promise<Object>} Setup result with QR code
   */
  setup2FA: (userId) => {
    return apiRequest(ENDPOINTS.SETUP_2FA, {
      ...defaultOptions,
      method: 'POST',
      body: JSON.stringify({ userId })
    });
  },
  
  /**
   * Confirm 2FA setup
   * @param {string} code - 2FA verification code
   * @param {string} userId - User ID to confirm 2FA for
   * @param {string} secret - 2FA secret to confirm
   * @returns {Promise<Object>} Confirmation result
   */
  confirm2FASetup: (code, userId, secret) => {
    return apiRequest(ENDPOINTS.CONFIRM_2FA_SETUP, {
      ...defaultOptions,
      method: 'POST',
      body: JSON.stringify({ code, userId, secret })
    });
  }
};

// Admin API
export const adminApi = {
  /**
   * Get users with pagination, sorting and filtering
   * @param {Object} params - Query parameters
   * @param {number} params.page - Page number (default: 1)
   * @param {number} params.limit - Items per page (default: 20)
   * @param {string} params.sort - Sort field (registrationDate, lastAiAttempt, totalSpent)
   * @param {string} params.order - Sort order (asc, desc)
   * @param {Object} params.filters - Filter criteria
   * @returns {Promise<Object>} Users data with pagination
   */
  getUsers: (params = {}) => {
    const queryParams = new URLSearchParams();
    
    if (params.page) queryParams.append('page', params.page);
    if (params.limit) queryParams.append('limit', params.limit);
    if (params.sort) queryParams.append('sort', params.sort);
    if (params.order) queryParams.append('order', params.order);
    
    // Handle filters object - convert to JSON string and properly encode
    if (params.filters && Object.keys(params.filters).length > 0) {
      const filtersJson = JSON.stringify(params.filters);
      queryParams.append('filters', filtersJson);
    }
    
    const endpoint = `${ENDPOINTS.ADMIN_USERS}?${queryParams.toString()}`;
    return apiRequest(endpoint, addAuthHeader(defaultOptions));
  },
  
  /**
   * Get total user count with optional filters
   * @param {Object} filters - Filter criteria (same format as getUsers)
   * @returns {Promise<Object>} Count object
   */
  getUserCount: (filters = {}) => {
    const queryParams = new URLSearchParams();
    
    if (Object.keys(filters).length > 0) {
      queryParams.append('filters', JSON.stringify(filters));
    }
    
    const endpoint = `${ENDPOINTS.ADMIN_USERS_COUNT}?${queryParams.toString()}`;
    return apiRequest(endpoint, addAuthHeader(defaultOptions));
  },
  
  /**
   * Get user metadata (available filters)
   * @returns {Promise<Object>} Metadata object
   */
  getUserMetadata: () => {
    return apiRequest(ENDPOINTS.ADMIN_USERS_METADATA, addAuthHeader(defaultOptions));
  },
  
  /**
   * Get all segments
   * @returns {Promise<Object>} Segments list
   */
  getSegments: () => {
    return apiRequest(ENDPOINTS.ADMIN_SEGMENTS, addAuthHeader(defaultOptions));
  },
  
  /**
   * Get segment by ID
   * @param {string} id - Segment ID
   * @returns {Promise<Object>} Segment details
   */
  getSegmentById: (id) => {
    return apiRequest(`${ENDPOINTS.ADMIN_SEGMENTS}/${id}`, addAuthHeader(defaultOptions));
  },
  
  /**
   * Create a new segment
   * @param {Object} segmentData - Segment data
   * @param {string} segmentData.name - Segment name
   * @param {string} segmentData.description - Segment description
   * @param {Object} segmentData.filterCriteria - Filter criteria
   * @returns {Promise<Object>} Created segment
   */
  createSegment: (segmentData) => {
    return apiRequest(ENDPOINTS.ADMIN_SEGMENTS, {
      ...addAuthHeader(defaultOptions),
      method: 'POST',
      body: JSON.stringify(segmentData)
    });
  },
  
  /**
   * Update a segment
   * @param {string} id - Segment ID
   * @param {Object} segmentData - Updated segment data
   * @returns {Promise<Object>} Updated segment
   */
  updateSegment: (id, segmentData) => {
    return apiRequest(`${ENDPOINTS.ADMIN_SEGMENTS}/${id}`, {
      ...addAuthHeader(defaultOptions),
      method: 'PUT',
      body: JSON.stringify(segmentData)
    });
  },
  
  /**
   * Delete a segment
   * @param {string} id - Segment ID
   * @returns {Promise<void>}
   */
  deleteSegment: (id) => {
    return apiRequest(`${ENDPOINTS.ADMIN_SEGMENTS}/${id}`, {
      ...addAuthHeader(defaultOptions),
      method: 'DELETE'
    });
  },
  
  /**
   * Get all email templates with optional pagination and search
   * @param {Object} params - Query parameters
   * @param {number} params.page - Page number (default: 1)
   * @param {number} params.limit - Items per page (default: 10)
   * @param {string} params.search - Search term for filtering templates
   * @returns {Promise<Object>} Templates data with pagination
   */
  getEmailTemplates: (params = {}) => {
    const queryParams = new URLSearchParams();
    
    if (params.page) queryParams.append('page', params.page);
    if (params.limit) queryParams.append('limit', params.limit);
    if (params.search) queryParams.append('search', params.search);
    
    const endpoint = `${ENDPOINTS.ADMIN_EMAIL_TEMPLATES}?${queryParams.toString()}`;
    return apiRequest(endpoint, addAuthHeader(defaultOptions));
  },
  
  /**
   * Get email template by ID
   * @param {string} id - Template ID
   * @returns {Promise<Object>} Template details
   */
  getEmailTemplateById: (id) => {
    return apiRequest(`${ENDPOINTS.ADMIN_EMAIL_TEMPLATES}/${id}`, addAuthHeader(defaultOptions));
  },
  
  /**
   * Create a new email template
   * @param {Object} templateData - Template data with defaultContent
   * @param {string} templateData.name - Template name
   * @param {string} templateData.description - Template description
   * @param {Object} templateData.defaultContent - Default template content
   * @param {string} templateData.defaultContent.language - Default language
   * @param {string} templateData.defaultContent.subject - Subject line
   * @param {string} templateData.defaultContent.htmlContent - HTML content
   * @param {string} templateData.defaultContent.textContent - Optional text content
   * @returns {Promise<Object>} Created template
   */
  createEmailTemplate: (templateData) => {
    return apiRequest(ENDPOINTS.ADMIN_EMAIL_TEMPLATES, {
      ...addAuthHeader(defaultOptions),
      method: 'POST',
      body: JSON.stringify(templateData)
    });
  },
  
  /**
   * Update an email template
   * @param {string} id - Template ID
   * @param {Object} templateData - Updated template data with defaultContent
   * @param {string} templateData.name - Template name
   * @param {string} templateData.description - Template description
   * @param {Object} templateData.defaultContent - Default template content
   * @param {string} templateData.defaultContent.language - Default language
   * @param {string} templateData.defaultContent.subject - Subject line
   * @param {string} templateData.defaultContent.htmlContent - HTML content
   * @param {string} templateData.defaultContent.textContent - Optional text content
   * @returns {Promise<Object>} Updated template
   */
  updateEmailTemplate: (id, templateData) => {
    return apiRequest(`${ENDPOINTS.ADMIN_EMAIL_TEMPLATES}/${id}`, {
      ...addAuthHeader(defaultOptions),
      method: 'PUT',
      body: JSON.stringify(templateData)
    });
  },
  
  /**
   * Delete an email template
   * @param {string} id - Template ID
   * @returns {Promise<Object>} Response with success status
   */
  deleteEmailTemplate: (id) => {
    return apiRequest(`${ENDPOINTS.ADMIN_EMAIL_TEMPLATES}/${id}`, {
      ...addAuthHeader(defaultOptions),
      method: 'DELETE'
    });
  },
  
  /**
   * Validate an email template
   * @param {Object} templateData - Template data to validate
   * @param {Object} templateData.defaultContent - Template content with language, subject, and htmlContent
   * @returns {Promise<Object>} Validation result
   */
  validateEmailTemplate: (templateData) => {
    return apiRequest(ENDPOINTS.ADMIN_EMAIL_TEMPLATES_VALIDATE, {
      ...addAuthHeader(defaultOptions),
      method: 'POST',
      body: JSON.stringify(templateData)
    });
  },
  
  /**
   * Test an email template
   * @param {Object} templateData - Template data to test
   * @param {Object} templateData.defaultContent - Default content 
   * @param {string} templateData.defaultContent.subject - Email subject
   * @param {string} templateData.defaultContent.htmlContent - Email HTML content
   * @param {string} templateData.defaultContent.textContent - Email text content (optional)
   * @param {string} testEmail - Email address to send test to
   * @param {string} language - Language to use for the test email (optional, defaults to defaultContent.language)
   * @returns {Promise<Object>} Test result with success status and message
   */
  testEmailTemplate: (templateData, testEmail, language) => {
    // Make sure receiverEmail is set (this is now required by the backend)
    const testContent = templateData.defaultContent || {};
    const receiverEmail = templateData.receiverEmail || testEmail;
    
    // Use the explicitly passed language, or get it from current content, or fall back to 'en'
    const contentLanguage = language || testContent.language || 'en';
    
    // Create the data structure expected by the backend according to TestTemplateRequest
    const data = {
      content: {
        subject: testContent.subject || templateData.subject || '',
        htmlContent: testContent.htmlContent || templateData.htmlContent || '',
        textContent: testContent.textContent || templateData.textContent || '',
        language: contentLanguage
      },
      sampleVariables: {}, // Empty map for sample variables
      receiverEmail: receiverEmail,
      templateId: templateData.id || null
    };
    
    console.log('Sending test email with data:', JSON.stringify(data, null, 2));
    
    return apiRequest(ENDPOINTS.ADMIN_EMAIL_TEMPLATES_TEST, {
      ...addAuthHeader(defaultOptions),
      method: 'POST',
      body: JSON.stringify(data)
    });
  },
  
  /**
   * Enhance an email template for better email client compatibility
   * @param {Object} templateData - Template data to enhance
   * @param {Object} templateData.defaultContent - Default content with htmlContent field
   * @returns {Promise<Object>} Enhanced template with optimized HTML
   */
  enhanceEmailTemplate: (templateData) => {
    // Extract htmlContent from the new structure
    const htmlContent = templateData.defaultContent?.htmlContent || templateData.htmlContent || '';
    const language = templateData.defaultContent?.language || 'en';
    const subject = templateData.defaultContent?.subject || '';
    const textContent = templateData.defaultContent?.textContent || '';
    
    console.log("API: enhanceEmailTemplate called with", {
      hasDefaultContent: !!templateData.defaultContent,
      htmlContentLength: htmlContent.length,
      subject: subject?.substring(0, 20)
    });
    
    // Format request according to new API structure
    const data = {
      content: {
        language: language,
        subject: subject,
        htmlContent: htmlContent,
        textContent: textContent
      }
    };
    
    const result = apiRequest(ENDPOINTS.ADMIN_EMAIL_TEMPLATES_ENHANCE, {
      ...addAuthHeader(defaultOptions),
      method: 'POST',
      body: JSON.stringify(data)
    });
    
    // Add a then handler to log the result before returning it
    result.then(response => {
      console.log("API: enhanceEmailTemplate response received", {
        success: response.success,
        hasContent: !!response.content,
        htmlContentLength: response.content?.htmlContent?.length
      });
    }).catch(error => {
      console.error("API: enhanceEmailTemplate error", error);
    });
    
    return result;
  },
  
  /**
   * Translate an email template to multiple languages
   * @param {string} templateId - ID of the template to translate
   * @param {Array<string>} targetLanguages - Array of language codes to translate to (e.g. ['es', 'fr', 'de'])
   * @returns {Promise<Object>} Translations for each requested language
   * @deprecated Use translateEmailTemplateContent instead for the new translation flow
   */
  translateEmailTemplate: (templateId, targetLanguages) => {
    const data = {
      templateId,
      targetLanguages
    };
    
    return apiRequest(ENDPOINTS.ADMIN_EMAIL_TEMPLATES_TRANSLATE, {
      ...addAuthHeader(defaultOptions),
      method: 'POST',
      body: JSON.stringify(data)
    });
  },

  /**
   * Translate template content from source language to target language
   * @param {Object} content - The template content to translate
   * @param {string} sourceLanguage - Source language code (e.g. 'en')
   * @param {string} targetLanguage - Target language code (e.g. 'es')
   * @returns {Promise<Object>} Translated content for the target language
   */
  translateEmailTemplateContent: (content, sourceLanguage, targetLanguage) => {
    const data = {
      content,
      sourceLanguage,
      targetLanguage
    };
    
    return apiRequest(ENDPOINTS.ADMIN_EMAIL_TEMPLATES_TRANSLATE, {
      ...addAuthHeader(defaultOptions),
      method: 'POST',
      body: JSON.stringify(data)
    });
  },

  /**
   * Get all email campaigns
   * @returns {Promise<Object>} List of email campaigns
   */
  getEmailCampaigns: () => {
    return apiRequest(ENDPOINTS.ADMIN_EMAIL_CAMPAIGNS, addAuthHeader(defaultOptions));
  },

  /**
   * Get a specific email campaign by ID
   * @param {string} id - Campaign ID
   * @returns {Promise<Object>} Campaign details
   */
  getEmailCampaignById: (id) => {
    return apiRequest(`${ENDPOINTS.ADMIN_EMAIL_CAMPAIGNS}/${id}`, addAuthHeader(defaultOptions));
  },

  /**
   * Create a new email campaign
   * @param {Object} campaignData - Campaign data
   * @param {string} campaignData.name - Campaign name
   * @param {string} campaignData.subject - Email subject line (can include variables like {{user.name}})
   * @param {string} campaignData.templateId - ID of the template to use
   * @param {string} campaignData.segmentId - ID of the user segment to target
   * @param {boolean} campaignData.trackOpens - Whether to track email opens
   * @param {boolean} campaignData.trackClicks - Whether to track link clicks
   * @returns {Promise<Object>} Created campaign
   */
  createEmailCampaign: (campaignData) => {
    return apiRequest(ENDPOINTS.ADMIN_EMAIL_CAMPAIGNS, {
      ...addAuthHeader(defaultOptions),
      method: 'POST',
      body: JSON.stringify(campaignData)
    });
  },

  /**
   * Update an email campaign
   * @param {string} id - Campaign ID
   * @param {Object} campaignData - Updated campaign data
   * @returns {Promise<Object>} Updated campaign
   */
  updateEmailCampaign: (id, campaignData) => {
    return apiRequest(`${ENDPOINTS.ADMIN_EMAIL_CAMPAIGNS}/${id}`, {
      ...addAuthHeader(defaultOptions),
      method: 'PUT',
      body: JSON.stringify(campaignData)
    });
  },

  /**
   * Schedule an email campaign for sending
   * @param {string} id - Campaign ID
   * @param {Object} scheduleData - Schedule data
   * @param {number} scheduleData.scheduledTime - Time to send the campaign (milliseconds since epoch)
   * @returns {Promise<Object>} Scheduled campaign
   */
  scheduleEmailCampaign: (id, scheduleData) => {
    // Validate the scheduledTime is in the future
    const now = Date.now();
    if (scheduleData.scheduledTime <= now) {
      return Promise.reject(new Error('Scheduled time must be in the future'));
    }
    
    console.log(`Scheduling campaign ${id} for time: ${new Date(scheduleData.scheduledTime).toISOString()}`);
    
    // Replace the :id param in the endpoint with the actual ID
    const endpoint = ENDPOINTS.ADMIN_EMAIL_CAMPAIGN_SCHEDULE.replace(':id', id);
    
    return apiRequest(endpoint, {
      ...addAuthHeader(defaultOptions),
      method: 'POST',
      body: JSON.stringify(scheduleData)
    });
  },

  /**
   * Cancel a scheduled email campaign
   * @param {string} id - Campaign ID
   * @returns {Promise<Object>} Cancelled campaign
   */
  cancelEmailCampaign: (id) => {
    // Replace the :id param in the endpoint with the actual ID
    const endpoint = ENDPOINTS.ADMIN_EMAIL_CAMPAIGN_CANCEL.replace(':id', id);
    
    return apiRequest(endpoint, {
      ...addAuthHeader(defaultOptions),
      method: 'POST'
    });
  },

  /**
   * Send a test email for a campaign
   * @param {string} id - Campaign ID
   * @param {Object} testData - Test data
   * @param {string} testData.receiverEmail - Email address to send the test to
   * @returns {Promise<Object>} Test result
   */
  testEmailCampaign: (id, testData) => {
    // Replace the :id param in the endpoint with the actual ID
    const endpoint = ENDPOINTS.ADMIN_EMAIL_CAMPAIGN_TEST.replace(':id', id);
    
    return apiRequest(endpoint, {
      ...addAuthHeader(defaultOptions),
      method: 'POST',
      body: JSON.stringify(testData)
    });
  },

  /**
   * Get analytics for a specific campaign
   * @param {string} id - Campaign ID
   * @returns {Promise<Object>} Campaign analytics
   */
  getEmailCampaignAnalytics: (id) => {
    // Replace the :id param in the endpoint with the actual ID
    const endpoint = ENDPOINTS.ADMIN_EMAIL_CAMPAIGN_ANALYTICS.replace(':id', id);
    
    return apiRequest(endpoint, addAuthHeader(defaultOptions));
  }
};

// Create API object for exports
const apiService = {
  // Include authentication API
  auth: authApi,
  
  // Include admin API
  admin: adminApi,
  
  // Generic request method
  request: apiRequest,
  
  // Helper for authenticated requests
  authenticatedRequest: (endpoint, options = {}) => {
    return apiRequest(endpoint, addAuthHeader(options));
  }
};

// Export the API service
export default apiService;