import React from 'react';
import { 
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  Typography
} from '@mui/material';

const CampaignForm = ({ 
  open, 
  onClose, 
  campaignForm,
  onFormChange,
  onSubmit,
  isEdit,
  templates,
  segments,
  colors
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        {isEdit ? 'Edit Campaign' : 'Create New Campaign'}
      </DialogTitle>
      
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 0 }}>
          <Grid item xs={12}>
            <TextField
              name="name"
              label="Campaign Name"
              value={campaignForm.name}
              onChange={onFormChange}
              fullWidth
              required
              margin="normal"
              variant="outlined"
            />
          </Grid>
          

          <Grid item xs={12} md={6}>
            <FormControl fullWidth margin="normal" required>
              <InputLabel>Email Template</InputLabel>
              <Select
                name="templateId"
                value={campaignForm.templateId}
                onChange={onFormChange}
                label="Email Template"
              >
                {templates.map((template) => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <FormControl fullWidth margin="normal" required>
              <InputLabel>Recipient Segment</InputLabel>
              <Select
                name="segmentId"
                value={campaignForm.segmentId}
                onChange={onFormChange}
                label="Recipient Segment"
              >
                {segments.map((segment) => (
                  <MenuItem key={segment.id} value={segment.id}>
                    {segment.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="text.secondary" sx={{ mt: 2, mb: 1 }}>
              Tracking Options
            </Typography>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Switch
                  name="trackOpens"
                  checked={campaignForm.trackOpens}
                  onChange={onFormChange}
                  color="primary"
                />
              }
              label="Track Email Opens"
            />
          </Grid>
          
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Switch
                  name="trackClicks"
                  checked={campaignForm.trackClicks}
                  onChange={onFormChange}
                  color="primary"
                />
              }
              label="Track Link Clicks"
            />
          </Grid>
        </Grid>
      </DialogContent>
      
      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button 
          onClick={onSubmit}
          variant="contained"
          sx={{ background: colors.gradient }}
        >
          {isEdit ? 'Update Campaign' : 'Create Campaign'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CampaignForm;