import React from 'react';
import { Chip } from '@mui/material';
import { 
  Edit as EditIcon,
  Schedule as ScheduleIcon,
  Send as SendIcon,
  CheckCircle as SuccessIcon,
  Cancel as CancelIcon,
  ErrorOutline as ErrorIcon
} from '@mui/icons-material';

/**
 * Campaign Status Flow:
 * 1. DRAFT - Initial state; can be updated or scheduled
 * 2. SCHEDULED - Campaign scheduled for future sending; can be cancelled 
 * 3. SENDING - Campaign currently in progress
 * 4. COMPLETED - Campaign successfully sent to all recipients
 * 5. CANCELLED - Campaign was cancelled before sending
 * 6. FAILED - Campaign encountered an error during sending
 */
const StatusChip = ({ status }) => {
  let color;
  let icon;
  
  switch (status) {
    case 'DRAFT':
      color = 'default';
      icon = <EditIcon fontSize="small" />;
      break;
    case 'SCHEDULED':
      color = 'primary';
      icon = <ScheduleIcon fontSize="small" />;
      break;
    case 'SENDING':
      color = 'warning';
      icon = <SendIcon fontSize="small" />;
      break;
    case 'COMPLETED':
      color = 'success';
      icon = <SuccessIcon fontSize="small" />;
      break;
    case 'CANCELLED':
      color = 'error';
      icon = <CancelIcon fontSize="small" />;
      break;
    case 'FAILED':
      color = 'error';
      icon = <ErrorIcon fontSize="small" />;
      break;
    case 'SENT': // Legacy status, map to COMPLETED
      color = 'success';
      icon = <SuccessIcon fontSize="small" />;
      break;
    default:
      color = 'default';
      icon = null;
  }
  
  // Display COMPLETED instead of SENT for consistency with API
  const displayStatus = status === 'SENT' ? 'COMPLETED' : status;
  
  return (
    <Chip 
      icon={icon}
      label={displayStatus}
      size="small"
      color={color}
      sx={{ fontWeight: 500 }}
    />
  );
};

export default StatusChip;