import React from 'react';
import { 
  Button,
  Typography, 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@mui/material';

const TestEmailDialog = ({ 
  open, 
  onClose, 
  testEmail,
  onTestEmailChange,
  onSendTest,
  colors
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
    >
      <DialogTitle>Send Test Email</DialogTitle>
      
      <DialogContent>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Send a test email to verify how it will look to recipients:
        </Typography>
        
        <TextField
          label="Email Address"
          value={testEmail}
          onChange={(e) => onTestEmailChange(e.target.value)}
          fullWidth
          required
          margin="normal"
          variant="outlined"
          type="email"
        />
      </DialogContent>
      
      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button 
          onClick={onSendTest}
          variant="contained"
          disabled={!testEmail}
          sx={{ background: colors.gradient }}
        >
          Send Test Email
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TestEmailDialog;