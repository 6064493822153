import React from 'react';
import { 
  Box, 
  Button,
  Typography, 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Alert
} from '@mui/material';
import { 
  PeopleOutline as AudienceIcon
} from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const ScheduleDialog = ({ 
  open, 
  onClose, 
  campaign, 
  scheduledTime,
  onScheduledTimeChange,
  onSchedule,
  getSegmentName,
  colors
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Schedule Campaign</DialogTitle>
      
      <DialogContent>
        {campaign && (
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" fontWeight="500">
              {campaign.name}
            </Typography>
            {campaign.segmentId && (
              <Chip 
                icon={<AudienceIcon fontSize="small" />} 
                label={`Segment: ${getSegmentName(campaign.segmentId)}`}
                size="small"
                variant="outlined"
                sx={{ mt: 1 }}
              />
            )}
          </Box>
        )}
        
        <Alert severity="info" sx={{ mb: 3 }}>
          <Typography variant="body2">
            Once scheduled, the campaign will be queued for sending at the specified time.
            You can cancel scheduled campaigns before they begin sending.
          </Typography>
        </Alert>
        
        <Typography variant="body2" sx={{ mb: 2, fontWeight: 500 }}>
          Select when to send this campaign:
        </Typography>
        
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label="Scheduled Time"
            value={dayjs(scheduledTime)}
            onChange={(newValue) => onScheduledTimeChange(newValue ? newValue.toDate() : null)}
            minDateTime={dayjs(new Date(Date.now() + 5 * 60 * 1000))} // 5 minutes from now
            format="MMM D, YYYY h:mm A" 
            sx={{ width: '100%' }}
            slotProps={{ textField: { fullWidth: true } }}
          />
        </LocalizationProvider>
        
        <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1 }}>
          Campaigns must be scheduled at least 5 minutes in the future.
        </Typography>
      </DialogContent>
      
      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button 
          onClick={onSchedule}
          variant="contained"
          sx={{ background: colors.gradient }}
        >
          Schedule Campaign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleDialog;