import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

/**
 * Component to display campaign metrics as circular progress charts
 */
const CampaignMetricsChart = ({ 
  openRate = 0, 
  clickRate = 0, 
  bounceRate = 0, 
  size = 60, 
  colors
}) => {
  // Default colors if not provided
  const defaultColors = {
    opens: '#4caf50', // green
    clicks: '#2196f3', // blue
    bounces: '#f44336', // red
    text: '#666666'
  };

  const chartColors = colors || defaultColors;

  // Common styling for all charts
  const commonStyles = {
    strokeWidth: 10,
    textSize: '25px',
    pathTransitionDuration: 0.5,
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'space-around', 
      alignItems: 'center',
      width: '100%',
      mt: 1
    }}>
      {/* Opens Rate */}
      <Box sx={{ textAlign: 'center', width: size }}>
        <Tooltip title={`Open rate: ${openRate.toFixed(1)}%`} arrow>
          <Box sx={{ width: size, height: size }}>
            <CircularProgressbar
              value={openRate}
              maxValue={100}
              text={`${Math.round(openRate)}%`}
              styles={buildStyles({
                ...commonStyles,
                pathColor: chartColors.opens,
                textColor: chartColors.text,
                trailColor: '#eeeeee',
              })}
            />
          </Box>
        </Tooltip>
        <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 0.5 }}>
          Opens
        </Typography>
      </Box>

      {/* Click Rate */}
      <Box sx={{ textAlign: 'center', width: size }}>
        <Tooltip title={`Click rate: ${clickRate.toFixed(1)}%`} arrow>
          <Box sx={{ width: size, height: size }}>
            <CircularProgressbar
              value={clickRate}
              maxValue={100}
              text={`${Math.round(clickRate)}%`}
              styles={buildStyles({
                ...commonStyles,
                pathColor: chartColors.clicks,
                textColor: chartColors.text,
                trailColor: '#eeeeee',
              })}
            />
          </Box>
        </Tooltip>
        <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 0.5 }}>
          Clicks
        </Typography>
      </Box>

      {/* Bounce Rate */}
      <Box sx={{ textAlign: 'center', width: size }}>
        <Tooltip title={`Bounce rate: ${bounceRate.toFixed(1)}%`} arrow>
          <Box sx={{ width: size, height: size }}>
            <CircularProgressbar
              value={bounceRate}
              maxValue={100}
              text={`${Math.round(bounceRate)}%`}
              styles={buildStyles({
                ...commonStyles,
                pathColor: chartColors.bounces,
                textColor: chartColors.text,
                trailColor: '#eeeeee',
              })}
            />
          </Box>
        </Tooltip>
        <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 0.5 }}>
          Bounces
        </Typography>
      </Box>
    </Box>
  );
};

export default CampaignMetricsChart;