import React from 'react';
import { 
  Button,
  Typography, 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';

const CancelConfirmDialog = ({ 
  open, 
  onClose, 
  onConfirmCancel
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
    >
      <DialogTitle>Cancel Campaign?</DialogTitle>
      
      <DialogContent>
        <Typography variant="body1">
          Are you sure you want to cancel this scheduled campaign? This action cannot be undone.
        </Typography>
      </DialogContent>
      
      <DialogActions>
        <Button onClick={onClose}>
          No, Keep It
        </Button>
        <Button 
          onClick={onConfirmCancel}
          variant="contained"
          color="error"
        >
          Yes, Cancel Campaign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelConfirmDialog;