import React from 'react';
import { 
  Box, 
  Button,
  Typography, 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Card,
  CardContent,
  LinearProgress,
  CircularProgress
} from '@mui/material';

const AnalyticsDialog = ({ 
  open, 
  onClose, 
  campaignAnalytics, 
  loading,
  formatDate
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Campaign Analytics</DialogTitle>
      
      <DialogContent>
        {loading || !campaignAnalytics ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6">
                {campaignAnalytics.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Sent on {formatDate(campaignAnalytics.sentAt)}
              </Typography>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" align="center">
                    {campaignAnalytics.totalRecipients}
                  </Typography>
                  <Typography variant="body2" align="center" color="text.secondary">
                    Total Recipients
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" align="center">
                    {campaignAnalytics.openRate}%
                  </Typography>
                  <Typography variant="body2" align="center" color="text.secondary">
                    Open Rate
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" align="center">
                    {campaignAnalytics.clickRate}%
                  </Typography>
                  <Typography variant="body2" align="center" color="text.secondary">
                    Click Rate
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Engagement Metrics
              </Typography>
              
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <Box>
                    <Typography variant="body2" color="text.secondary">Opens</Typography>
                    <Typography variant="h6">{campaignAnalytics.totalOpens}</Typography>
                    <LinearProgress 
                      variant="determinate" 
                      value={(campaignAnalytics.totalOpens / campaignAnalytics.totalRecipients) * 100} 
                      sx={{ mt: 1, height: 6, borderRadius: 3 }}
                    />
                  </Box>
                </Grid>
                
                <Grid item xs={6} md={3}>
                  <Box>
                    <Typography variant="body2" color="text.secondary">Clicks</Typography>
                    <Typography variant="h6">{campaignAnalytics.totalClicks}</Typography>
                    <LinearProgress 
                      variant="determinate" 
                      value={(campaignAnalytics.totalClicks / campaignAnalytics.totalRecipients) * 100} 
                      sx={{ mt: 1, height: 6, borderRadius: 3 }}
                    />
                  </Box>
                </Grid>
                
                <Grid item xs={6} md={3}>
                  <Box>
                    <Typography variant="body2" color="text.secondary">Bounces</Typography>
                    <Typography variant="h6">{campaignAnalytics.totalBounces}</Typography>
                    <LinearProgress 
                      variant="determinate" 
                      value={(campaignAnalytics.totalBounces / campaignAnalytics.totalRecipients) * 100} 
                      sx={{ mt: 1, height: 6, borderRadius: 3 }}
                      color="error"
                    />
                  </Box>
                </Grid>
                
                <Grid item xs={6} md={3}>
                  <Box>
                    <Typography variant="body2" color="text.secondary">Unsubscribes</Typography>
                    <Typography variant="h6">{campaignAnalytics.totalUnsubscribes}</Typography>
                    <LinearProgress 
                      variant="determinate" 
                      value={(campaignAnalytics.totalUnsubscribes / campaignAnalytics.totalRecipients) * 100} 
                      sx={{ mt: 1, height: 6, borderRadius: 3 }}
                      color="warning"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Device Breakdown
              </Typography>
              
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="body2">Desktop</Typography>
                  <Typography variant="body2">{campaignAnalytics.deviceBreakdown.desktopPercentage}%</Typography>
                </Box>
                <LinearProgress 
                  variant="determinate" 
                  value={campaignAnalytics.deviceBreakdown.desktopPercentage} 
                  sx={{ mb: 2, height: 6, borderRadius: 3 }}
                />
                
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="body2">Mobile</Typography>
                  <Typography variant="body2">{campaignAnalytics.deviceBreakdown.mobilePercentage}%</Typography>
                </Box>
                <LinearProgress 
                  variant="determinate" 
                  value={campaignAnalytics.deviceBreakdown.mobilePercentage} 
                  sx={{ mb: 2, height: 6, borderRadius: 3 }}
                />
                
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="body2">Tablet</Typography>
                  <Typography variant="body2">{campaignAnalytics.deviceBreakdown.tabletPercentage}%</Typography>
                </Box>
                <LinearProgress 
                  variant="determinate" 
                  value={campaignAnalytics.deviceBreakdown.tabletPercentage} 
                  sx={{ mb: 2, height: 6, borderRadius: 3 }}
                />
              </Box>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Most Clicked Links
              </Typography>
              
              {campaignAnalytics.mostClickedLinks.map((link, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography variant="body2" noWrap sx={{ maxWidth: '70%' }}>
                      {link.url}
                    </Typography>
                    <Typography variant="body2">{link.clickRate}%</Typography>
                  </Box>
                  <LinearProgress 
                    variant="determinate" 
                    value={link.clickRate} 
                    sx={{ height: 6, borderRadius: 3 }}
                  />
                </Box>
              ))}
            </Grid>
          </Grid>
        )}
      </DialogContent>
      
      <DialogActions>
        <Button onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AnalyticsDialog;