import React from 'react';
import { 
  Box, 
  Button,
  Typography, 
  Card, 
  CardContent,
  Divider,
  Chip,
  CircularProgress
} from '@mui/material';
import { 
  Send as SendIcon,
  PeopleOutline as AudienceIcon,
  Schedule as ScheduleIcon,
  Edit as EditIcon,
  BarChart as AnalyticsIcon,
  Cancel as CancelIcon
} from '@mui/icons-material';
import StatusChip from './StatusChip';
import CampaignMetricsChart from './CampaignMetricsChart';
import { formatDate } from '../../../utils/dateUtils';

const CampaignCard = ({ 
  campaign, 
  colors,
  getSegmentName,
  getTemplateName,
  onSchedule,
  onEdit,
  onTest,
  onCancel,
  onViewAnalytics,
  onDuplicate
}) => {
  // For debugging purposes
  console.log(`Rendering campaign card for "${campaign.name}" with status: "${campaign.status}"`);
  
  return (
    <Card sx={{ mb: 2, borderRadius: 2, boxShadow: '0 2px 10px rgba(0,0,0,0.05)' }}>
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', mb: 2, gap: 2 }}>
          <Box>
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              {campaign.name}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              {campaign.status === 'SCHEDULED' 
                ? `Scheduled for ${formatDate(campaign.scheduledTime)}`
                : campaign.status === 'COMPLETED' || campaign.status === 'SENT'
                  ? `Sent on ${formatDate(campaign.sentAt || campaign.updatedAt)}`
                  : `Created on ${formatDate(campaign.createdAt)}`
              }
            </Typography>
            
            <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
              <StatusChip status={campaign.status} />
              {campaign.segmentId && (
                <Chip 
                  icon={<AudienceIcon fontSize="small" />} 
                  label={`Segment: ${getSegmentName(campaign.segmentId)}`}
                  size="small"
                  variant="outlined"
                />
              )}
            </Box>
          </Box>
          
          <Box sx={{ 
              display: 'flex', 
              flexWrap: 'wrap',
              alignItems: 'flex-start', 
              mt: { xs: 1, md: 0 },
              gap: 1
            }}>
            {/* DRAFT campaign actions */}
            {campaign.status === 'DRAFT' && (
              <>
                <Button 
                  variant="outlined" 
                  color="primary"
                  size="small" 
                  sx={{ }}
                  onClick={() => onSchedule(campaign)}
                  startIcon={<ScheduleIcon />}
                >
                  Schedule
                </Button>
                
                <Button 
                  variant="outlined" 
                  color="primary"
                  size="small" 
                  sx={{ }}
                  onClick={() => onTest(campaign)}
                  startIcon={<SendIcon />}
                >
                  Test
                </Button>
                
                <Button 
                  variant="contained" 
                  size="small"
                  startIcon={<EditIcon />}
                  onClick={() => onEdit(campaign)}
                  sx={{ 
                    background: colors.gradient,
                    boxShadow: 'none'
                  }}
                >
                  Edit
                </Button>
              </>
            )}
            
            {/* SCHEDULED campaign actions */}
            {campaign.status === 'SCHEDULED' && (
              <>
                <Button 
                  variant="outlined" 
                  size="small" 
                  color="error"
                  sx={{ }}
                  onClick={() => onCancel(campaign)}
                  startIcon={<CancelIcon />}
                >
                  Cancel
                </Button>
                
                <Button 
                  variant="outlined" 
                  color="primary"
                  size="small" 
                  sx={{ }}
                  onClick={() => onTest(campaign)}
                  startIcon={<SendIcon />}
                >
                  Test
                </Button>
              </>
            )}
            
            {/* COMPLETED or SENT campaign actions */}
            {['COMPLETED', 'SENT'].includes(campaign.status) && (
              <Button 
                variant="contained" 
                size="small"
                startIcon={<AnalyticsIcon />}
                onClick={() => onViewAnalytics(campaign)}
                sx={{ 
                  background: colors.gradient,
                  boxShadow: 'none'
                }}
              >
                View Report
              </Button>
            )}
            
            {/* SENDING campaign actions - show progress indicator */}
            {campaign.status === 'SENDING' && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CircularProgress size={20} sx={{ mr: 1 }} />
                <Typography variant="body2">Sending in progress...</Typography>
              </Box>
            )}
            
            {/* CANCELLED or FAILED campaign actions */}
            {['CANCELLED', 'FAILED'].includes(campaign.status) && (
              <Button 
                variant="outlined" 
                color="primary"
                size="small"
                startIcon={<EditIcon />}
                onClick={() => onDuplicate(campaign)}
              >
                Duplicate
              </Button>
            )}
            
            {/* Fallback button when no status match */}
            {!['DRAFT', 'SCHEDULED', 'COMPLETED', 'SENT', 'SENDING', 'CANCELLED', 'FAILED'].includes(campaign.status) && (
              <Button 
                variant="outlined" 
                color="primary"
                size="small"
                startIcon={<EditIcon />}
                onClick={() => onEdit(campaign)}
              >
                Edit
              </Button>
            )}
          </Box>
        </Box>
        
        <Divider sx={{ my: 2 }} />
        
        <Box sx={{ mt: 1, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'flex-start', md: 'center' } }}>
          <Typography variant="body2" color="text.secondary" sx={{ mr: 2 }}>
            Template: {getTemplateName(campaign.templateId)}
          </Typography>
          
          <Box sx={{ display: 'flex', alignItems: 'center', ml: { xs: 0, md: 'auto' }, mt: { xs: 1, md: 0 } }}>
            {campaign.trackOpens && (
              <Chip label="Track Opens" size="small" variant="outlined" sx={{ mr: 1 }} />
            )}
            {campaign.trackClicks && (
              <Chip label="Track Clicks" size="small" variant="outlined" />
            )}
          </Box>
        </Box>
        
        {/* Metrics section for completed campaigns */}
        {['COMPLETED', 'SENT'].includes(campaign.status) && campaign.metrics && (
          <>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ mt: 1 }}>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Campaign Performance
              </Typography>
              <CampaignMetricsChart 
                openRate={campaign.metrics.openRate || 0} 
                clickRate={campaign.metrics.clickRate || 0} 
                bounceRate={campaign.metrics.bounceRate || 0}
                size={50}
                colors={{
                  opens: colors.success || '#4caf50',
                  clicks: colors.primary || '#2196f3',
                  bounces: colors.error || '#f44336',
                  text: colors.text || '#666666',
                }}
              />
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default CampaignCard;